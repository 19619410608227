import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import VModal from 'vue-js-modal'
import Vuelidate from 'vuelidate'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import i18n from './i18n'

Vue.use(VueMaterial)
Vue.use(Vuelidate)
Vue.use(VModal, { dynamic: true, injectModalsContainer: true })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

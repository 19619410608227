<template>
  <div class="footer">
    <div class="footer-content">
      <div class="actions-container">
        <ul class="footer-links">
          <li><router-link to="/" class="nav-link">{{ $t('Home') }}</router-link></li>
          <li><router-link to="/about" class="nav-link">{{ $t('About') }}</router-link></li>
          <li><router-link to="/how-it-works" class="nav-link">{{ $t('how-it-works') }}</router-link></li>
          <li><a class="nav-link login-link" @click="callLogin">{{ $t('Login') }}</a></li>
        </ul>
      </div>
      <div class="actions-container">
        <div>{{ $t('Copyright') }} | {{ $t('all-rights-reserved')}}</div>
        <div>{{$t('privacy-policy')}} | {{$t('terms-and-conditions')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  methods: {
    callLogin () {
      this.$emit('open-login')
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "../styles/app-styles";

  .footer {
    display: flex;
    background-color: var(--footer-background-color);
    width: 100%;
    color: #ffffff;
    min-height: 300px;
    justify-content: center;
    align-items: center;

    .login-link {
      cursor: pointer;
    }

    &-content {
      margin: 0 auto;
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
    }

    &-content .actions-container {
      width: 100%;
      padding: 27px 0;
      display: flex;
      justify-content: space-between;
    }

    &-content .actions-container:first-child {
      border-bottom: 2px solid rgba(255,255,255,0.2);
    }

    &-content .actions-container ul.footer-links {
      list-style: none;
      padding-inline-start: 0;
      display: flex;
      width: 100%;
      min-width: 405px;
    }

    &-content .actions-container ul.footer-links a.nav-link {
      color: #ffffff !important;
      font-size: 18px;
      margin-right: 2rem;
    }

    /* Extra small devices (phone, 320px to 576px) */
    @media only screen and (max-width: $media_breakpoint_sm) and (min-width: $media_breakpoint_xsm) {
      .actions-container:first-child {
        display: none;
      }

      .actions-container:last-child {
        flex-direction: column;
      }
    }

    @media only screen and (max-width: $media_breakpoint_lg) {
      .footer-content {
        padding: 1rem;
      }
    }
  }
</style>

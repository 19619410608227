<template>
  <md-dialog :md-active.sync="showDialog" @md-opened="onDialogOpened">
    <md-dialog-title style="justify-content: flex-end"> <md-button class="md-icon-button md-primary" @click="close"><md-icon class="close-icon">close</md-icon ></md-button></md-dialog-title>
    <div style="padding: 1rem;">
      <h3>Register</h3>
      <p class="instruction">Please fill out the following below to create a new user.</p>
      <base-input-wrapper>
        <base-input label="Create username" v-model="username" :disabled="loading"></base-input>
      </base-input-wrapper>
      <base-input-wrapper>
        <div class="{ 'form-group-error': $v.password.$error }">
          <base-input type="password" label="Create password" v-model.trim="$v.password.$model" :disabled="loading"></base-input>
        </div>
      </base-input-wrapper>
      <base-input-wrapper>
        <div class="{ 'form-group-error': $v.repeatPassword.$error }">
          <base-input type="password" label="Repeat password" v-model.trim="$v.repeatPassword.$model" :disabled="loading"></base-input>
        </div>
      </base-input-wrapper>
      <ul v-if="!$v.password.$model" class="password-rules instruction">
        <li>Password is required.</li>
        <li>Password must be at least 6 characters.</li>
        <li>Password should contain at least one lowercase character.</li>
        <li>Password should contain at least one uppercase character.</li>
        <li>Password should contain at least one special character.</li>
        <li>Password should contain at least one number.</li>
        <li>Passwords must be identical</li>
      </ul>
      <ul class="password-rules instruction" v-if="$v.password.$model">
        <li :class="[{'is-correct': $v.password.$model !== '' && $v.password.$model != null}, {'is-not-correct': $v.password.$model === '' || $v.password.$model === null}]">
          <i class="material-icons rule-icon check-icon">done</i>
          <i class="material-icons rule-icon x-icon">close</i>
          Password is required.
        </li>
        <li :class="[{'is-correct': $v.password.minLength && $v.password.$model && $v.password.$model.length > 0 }, {'is-not-correct': !$v.password.minLength || !$v.password.$model}]">
          <i class="material-icons rule-icon check-icon">done</i>
          <i class="material-icons rule-icon x-icon">close</i>
          Password must be at least 6 characters.
        </li>
        <li :class="[{'is-correct': $v.password.$model && $v.password.$model.length && $v.password.hasLowercase}, {'is-not-correct': !$v.password.hasLowercase || !$v.password.$model}]">
          <i class="material-icons rule-icon check-icon">done</i>
          <i class="material-icons rule-icon x-icon">close</i>
          Password should contain at least one lowercase character.
        </li>
        <li :class="[{'is-correct': $v.password.$model && $v.password.$model.length > 0 && $v.password.hasUppercase}, {'is-not-correct': !$v.password.hasUppercase || !$v.password.$model}]">
          <i class="material-icons rule-icon check-icon">done</i>
          <i class="material-icons rule-icon x-icon">close</i>
          Password should contain at least one uppercase character.
        </li>
        <li :class="[{'is-correct': $v.password.$model && $v.password.$model.length > 0 && $v.password.hasSpecialCharacter}, {'is-not-correct': !$v.password.hasSpecialCharacter || !$v.password.$model}]">
          <i class="material-icons rule-icon check-icon">done</i>
          <i class="material-icons rule-icon x-icon">close</i>
          Password should contain at least one special character.
        </li>
        <li :class="[{'is-correct': $v.password.$model && $v.password.$model.length > 0 && $v.password.hasNumber}, {'is-not-correct': !$v.password.hasNumber || !$v.password.$model}]">
          <i class="material-icons rule-icon check-icon">done</i>
          <i class="material-icons rule-icon x-icon">close</i>
          Password should contain at least one number.
        </li>
        <li :class="[{'is-correct': $v.repeatPassword.$model && $v.repeatPassword.$model.length > 0 && $v.repeatPassword.sameAsPassword},{'is-not-correct': !$v.repeatPassword.sameAsPassword || !$v.repeatPassword.$model}]">
          <i class="material-icons rule-icon check-icon">done</i>
          <i class="material-icons rule-icon x-icon">close</i>
          Passwords must be identical.
        </li>
      </ul>
      <div class="error-message" v-if="hasError">
        {{ errorMessage }}
      </div>
    </div>
    <md-dialog-actions>
      <div style="display: flex; margin-right: 1rem; align-items: center; font-style: italic" v-if="loading">
        <md-progress-spinner class="spinner-icon" :md-diameter="30" md-mode="indeterminate"></md-progress-spinner>
        <p class="instruction">Logging in ...please wait</p>
      </div>
      <base-button @click="register" :disabled="loading || !(this.username && this.password && this.repeatPassword && $v.repeatPassword.sameAsPassword)">Register</base-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import BaseInputWrapper from '@/components/controls/base/BaseInputWrapper'
import BaseInput from '@/components/controls/base/BaseInput'
import BaseButton from '@/components/controls/base/BaseButton'
import UserService from '@/services/UserService'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

const hasSpecialCharacter = (password) => {
  return /[!@#\$%\^\&*\)\(+=._-]/.test(password);
}

const hasUppercase = (password) => {
  return /[A-Z]/.test(password)
}

const hasLowercase = (password) => {
  return /[a-z]/.test(password)
}

const hasNumber = (password) => {
  return /\d/.test(password)
}

export default {
  name: 'RegisterModal',
  components: {BaseButton, BaseInput, BaseInputWrapper},
  model: {
    event: 'open',
    prop: 'show'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      username: null,
      password: null,
      repeatPassword: null,
      loading: false,
      hasError: false,
      errorMessage: 'An error has occurred'
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
      hasSpecialCharacter: hasSpecialCharacter,
      hasLowercase: hasLowercase,
      hasUppercase: hasUppercase,
      hasNumber: hasNumber
    },
    repeatPassword: {
      sameAsPassword: sameAs('password')
    }
  },
  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.$emit('open', value)
      }
    }
  },
  watch: {
    showDialog(isShowing) {
      if(isShowing) {
        this.username = null
        this.password = null
        this.repeatPassword = null
      }
    }
  },
  created () {
    this.username = null
    this.password = null
    this.repeatPassword = null
  },
  methods: {

    ...mapActions({
      setUser: 'setUser'
    }),

    onDialogOpened () {
      // clear input values when the dialog opens
      this.username = null
      this.password = null
    },
    close () {
      this.$emit('close')
    },
    async register () {
      try{
        this.loading = true
        const registerResponse = await UserService.register(this.username, this.password)
        const registerResponseData = registerResponse.data.data
        const registerMessage = registerResponse.data.message
        const registerStatus = registerResponse.data.status
        if (registerStatus === 200) {
          const userSession = {
            token: registerResponseData.jwt,
            userName: this.username,
            library: null,
            libraryUserId: null
          }
          this.setUser(userSession)
          this.close()
        } else {
          if (registerMessage) {
            this.hasError = true
            this.errorMessage = registerMessage
          } else {
            this.hasError = true
          }
        }
      } catch (error) {
        alert(error.response)
        this.close()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/styles/app-styles";

.md-dialog /deep/.md-dialog-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  color: #ffffff;
  padding: 1rem;
  margin: 0;

  .close-icon {
    color: var(--button-and-link-colors) !important;
  }
}

.spinner-icon {
  margin-right: 1rem;
  color: var(--main-theme-color);
}

.error-message {
  color: red;
}

.md-dialog /deep/.md-dialog-container {
  min-width: 30%;
  max-width: 500px;
  width: 90%;
  max-height: 700px;
  height: auto;
}

.password-rules {
  list-style: none;
  list-style-type: none;
  padding: 0;
}

.is-correct {
  color: green;
}
.is-not-correct {
  color: red
}
.rule-icon {
  display: none;
  font-size: 0.75rem;
  font-weight: bold;
}

.is-correct .check-icon {
  display: inline;
}

.is-not-correct .check-icon {
  display: none;
}

.is-correct .x-icon {
  display: none;
}

.is-not-correct .x-icon {
  display: inline;
}
</style>

<template>
  <md-button class="md-primary base-button" :class="{'css-outline': cssOutline}" v-bind="$attrs" @click="$emit('click')"><slot></slot></md-button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    cssOutline: Boolean
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/app-styles.scss";
.base-button {
  color: #ffffff !important;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  background-color: var(--button-and-link-colors);
  min-width: 64px;
  min-height: 50px;
  border-radius: 10px;
}

.md-button[disabled]:not(.css-outline) {
  background-color: #cccccc;
}

.md-button.css-outline[disabled] {
  background-color: white;
  border-color: #cccccc;
  color: #cccccc !important;
}

.css-outline {
  background-color: transparent;
  border: 2px solid var(--button-and-link-colors);
  color: var(--button-and-link-colors) !important;
}

.css-outline:hover {
  background-color: var(--button-and-link-colors);
  color: #ffffff !important;
}
</style>

<template>
  <md-button class="md-primary translate-now-button" @click="goToTranslate">{{ $t('translate-now') }}</md-button>
</template>

<script>
export default {
  name: 'TranslateNowButton',
  methods: {
    goToTranslate () {
      this.$router.push({ name: 'TranslateNow' })
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/app-styles.scss";
.translate-now-button {
  color: #ffffff !important;
  font-size: 16px;
  font-weight: bold;
  background-color: var(--button-and-link-colors);
  min-width: 203px;
  min-height: 50px;
  border-radius: 10px;
}
</style>

export const storage = {

  storeValue (item, data) {
    if (localStorage.getItem(item)) {
      localStorage.removeItem(item)
      localStorage.setItem(item, JSON.stringify(data))
    } else {
      localStorage.setItem(item, JSON.stringify(data))
    }
  },

  getValue (item) {
    item = localStorage.getItem(item)
    if (item) {
      return JSON.parse(item)
    } else {
      return false
    }
  },

  removeItem (key) {
    localStorage.removeItem(key)
  },

  clearValue (item) {
    if (localStorage.getItem(item)) {
      localStorage.removeItem(item)
    }
  }
}

import { api } from '@/utils/api'

class FileService {
  postUpload (formData) {
    return api.uploadFile('/document/upload', { jwt: formData.jwt, file: formData.file })
  }

  save (formData) {
    return api.post('/document/save', formData)
  }
  removeFile(token, tempNewProductFileId) {
    const payload = {}
    payload.jwt = token
    payload['temp_new_product_file_id'] = tempNewProductFileId
    return api.delete('/document/remove', payload)
  }

  createFile(fileData) {
    return {
      id: null,
      lastModified: fileData.lastModified,
      lastModifiedDate: fileData.lastModifiedDate,
      name: fileData.name,
      webkitRelativePath: fileData.webkitRelativePath,
      size: fileData.size,
      type: fileData.type,
      fileInterface: fileData
    }
  }

}

export default new FileService()

<template>
  <div class="page-content">
    <base-card>
      <template v-slot:header>
        <h2>{{ $t('Checkout')}} - {{ $t('Success') }}</h2>
      </template>
      <template v-slot:content>
        <div class="success-message-content">
          <div>
            <p class="success-message large">{{ $t('Your document has been queued to process!') }}</p>
            <p class="success-message">{{$t('You don\'t need to stay here you will receive an email when your translation is ready.')}}</p>
            <p class="success-message translate-more">{{ $t('Need to translate more documents?') }}</p>
            <translate-now-button />
            <p>{{ $t('if-you-have-any-issues-reach-out-to') }} <a href="mailto:support@fluentconcepts.com">support@fluentconcepts.com</a></p>
          </div>
          <img id="successImage" :src="GummyReadingRoom" alt="Success Image" />
        </div>
      </template>
    </base-card>
  </div>
</template>

<script>
import GummyReadingRoom from '@/assets/gummy-reading-room.png'
import BaseCard from '@/components/BaseCard'
import TranslateNowButton from '@/components/TranslateNowButton'
export default {
  name: 'CheckoutSuccess',
  components: {
    TranslateNowButton,
    GummyReadingRoom,
    BaseCard
  },
  data () {
    return {
      GummyReadingRoom
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/app-styles.scss";

#successImage {
  max-width: 450px;
}

h1 {
  color: var(--main-theme-color);
}

.success-message {
  max-width: 500px;
  color: rgba(0,0,0,0.5);
  margin: 12px auto;
}

.success-message.large {
  font-size: 24px;
  font-weight: bold;
  color: var(--main-theme-color);
}

.success-message.translate-more {
  font-size: 16px;
  font-weight: bold;
}

.success-message-content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
</style>

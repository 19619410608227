import axios from 'axios'

export const api = {
  getJson(path) { return axios.get(path) },
  getRaw (path) { return axios.get(this._genUrl(path)) },
  getRawSpec (path) { return axios.get(path) },
  get (path, additionalHeaders) { return axios.get(this._genUrl(path), this._genOpts(additionalHeaders)) },
  rawPost (path, body) { return axios.post(this._genUrl(path), body) },
  rawPostSpec (path, body) { return axios.post(path, body) },
  post (path, body, additionalHeaders) { return axios.post(this._genUrl(path), body, this._genOpts(additionalHeaders)) },
  put (path, body, additionalHeaders) { return axios.put(this._genUrl(path), body, this._genOpts(additionalHeaders)) },
  patch (path, body, additionalHeaders) { return axios.patch(this._genUrl(path), body, this._genOpts(additionalHeaders)) },
  delete (path, body, additionalHeaders) {
    const config = {}
    if (body) {
      config.data = body
    }
    if (additionalHeaders) {
      config.headers = additionalHeaders.headers ? additionalHeaders.headers : {}
    }
    return axios.delete(this._genUrl(path), config)
  },
  uploadFile (path, body) {
    if (!body.file) {
      throw new Error('uploadFile: file was not defined')
    }
    const formData = new FormData()
    formData.append('file', body.file)

    const keys = Object.keys(body)
    keys.forEach((key) => {
      formData.append(key, body[key])
    })

    return axios.post(this._genUrl(path), formData, {
      formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  _genUrl (path) {
    let baseUrl = process.env.VUE_APP_ROOT_API
    if (!baseUrl.endsWith('/')) {
      baseUrl += '/'
    }
    let p = path
    if (p.startsWith('/')) {
      p = path.slice(1)
    }
    return `${baseUrl}${p}`
  },
  _genOpts (additionalHeaders) {
    const defaultHeaders = {}
    return { headers: Object.assign({}, defaultHeaders.headers, (additionalHeaders ? additionalHeaders.headers : {})) }
  }
}

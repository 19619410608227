<template>
  <md-dialog :md-active.sync="showDialog" @md-opened="onDialogOpened">
    <md-dialog-title>Login <md-button class="md-icon-button md-primary" @click="close"><md-icon class="close-icon">close</md-icon ></md-button></md-dialog-title>
<!--    <iframe id="loginFrame" ref="loginFrame" :src=loginUrl></iframe>-->
    <div style="padding: 1rem;">
      <base-input-wrapper>
        <base-input label="Username" v-model="username" :disabled="loading"></base-input>
      </base-input-wrapper>
      <base-input-wrapper>
        <base-input type="password" label="Password" v-model="password" :disabled="loading"></base-input>
      </base-input-wrapper>
      <div class="error-message" v-if="hasError">
        {{ errorMessage }}
      </div>
    </div>
    <md-dialog-actions>
      <div style="display: flex; margin-right: 1rem; align-items: center; font-style: italic" v-if="loading">
        <md-progress-spinner class="spinner-icon" :md-diameter="30" md-mode="indeterminate"></md-progress-spinner>
        <p class="instruction">Logging in ...please wait</p>
      </div>
      <base-button :css-outline="true" @click="close" :disabled="loading">Close</base-button>
      <base-button @click="login" :disabled="loading || !(this.username && this.password)">Login</base-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>

import { mapActions } from 'vuex'
import BaseInputWrapper from '@/components/controls/base/BaseInputWrapper'
import BaseInput from '@/components/controls/base/BaseInput'
import BaseButton from '@/components/controls/base/BaseButton'
import UserService from '@/services/UserService'

const devLogin = 'https://devlogin.librarypass.com'

export default {
  name: 'LoginModal',
  components: {BaseButton, BaseInput, BaseInputWrapper},
  model: {
    event: 'open',
    prop: 'show'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loginUrl: devLogin + '/?modal_login=1',
      trustedOrigin: devLogin,
      username: null,
      password: null,
      loading: false,
      hasError: false,
      errorMessage: 'An error has occurred'
    }
  },
  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.$emit('open', value)
      }
    }
  },
  mounted () {
    const addEventListener = window.addEventListener ? 'addEventListener' : 'attachEvent'
    const messageEvent = addEventListener === 'attachEvent' ? 'onmessage' : 'message'
    window[addEventListener](messageEvent, this.handleLogin)
  },
  beforeDestroy () {
    const removeEventListener = window.removeEventListener ? 'removeEventListener' : 'removeEvent'
    const messageEvent = removeEventListener === 'removeEvent' ? 'onmessage' : 'message'
    if (removeEventListener) {
      window[removeEventListener](messageEvent, this.handleLogin)
    }
  },
  methods: {

    ...mapActions({
      setUser: 'setUser'
    }),

    onDialogOpened () {
      // clear input values when the dialog opens
      this.username = null
      this.password = null
    },
    close () {
      this.$emit('close')
    },
    async login () {
      try{
        this.loading = true
        const loginResponse = await UserService.login(this.username, this.password)
        const loginResponseData = loginResponse.data.data
        const loginMessage = loginResponse.data.message
        const loginStatus = loginResponse.data.status
        if (loginStatus === 200) {
          const userSession = {
            token: loginResponseData.jwt,
            userName: this.username,
            library: null,
            libraryUserId: null
          }
          this.setUser(userSession)
          this.close()
        } else {
          if (loginMessage) {
            this.hasError = true
            this.errorMessage = loginMessage
          } else {
            this.hasError = true
          }
        }
      } catch (error) {
        alert(error.response)
        this.close()
      } finally {
        this.loading = false
      }
    },
    handleLogin (e) {
      if (e.origin !== this.trustedOrigin) return

      const data = e.data

      if (data.status === 'Success') {
        const userSession = {
          library: data.library,
          token: data.token,
          userName: data.user_name,
          libraryUserId: data.library_user_id
        }
        this.setUser(userSession)
        this.close()
      } else {
        alert('Bad login')
        this.close()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/styles/app-styles";

.md-dialog /deep/.md-dialog-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--main-theme-color);
  color: #ffffff;
  padding: 1rem;
  margin: 0;

  .close-icon {
    color: var(--button-and-link-colors) !important;
  }
}

.spinner-icon {
  margin-right: 1rem;
  color: var(--main-theme-color);
}

.error-message {
  color: red;
}

.md-dialog /deep/.md-dialog-container {
  min-width: 30%;
  max-width: 500px;
  width: 90%;
  max-height: 700px;
  height: auto;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>

import {api} from '@/utils/api'
import {AppUtils} from '@/utils/AppUtils'

class DocumentService {
  /**
   * This method returns the product_id
   *
   * @param jwt The string value of the session token
   * @returns {Object<status, product_id> }
   */
  getComplete(jwt) {
    // api will decode these values
    jwt = btoa(jwt)
    const host = btoa(AppUtils.getHost())
   return api.get(`/document/complete/${jwt}/${host}`)
  }

  getDownload(productId, email) {
    return api.get(`/download/${productId}/${email}`)
  }

  processDocuments(transactionId, email) {
    // NOTE: both the transactionId and email should already be encoded before this call is made
    const payload = {}
    payload['transaction_id'] = transactionId
    payload['email'] = email
    return api.post('/document/retail_process_page', payload)
  }
}

export default new DocumentService()

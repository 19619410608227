import { api } from '@/utils/api'

class UserService {
  lookupUser (userData) {
    return api.post('/user/lookup', userData)
  }

  processTranslation(processData) {
    const payload = {
      libraryUserId: processData.libraryUserId,
      libraryId: processData.libraryId,
      userName: processData.userName,
      originalFileName: processData.originalFileName,
      email: processData.email,
      productId: processData.productId,
      jwt: processData.jwt
    }
    return api.post('/user/process_translation', payload)
  }

  postUpdate(userData) {
    const payload = {
      jwt: userData.jwt,
      fingerprint: userData.fingerprint,
      email: userData.email
    }
    return api.post('/user/update', payload)
  }

  login(username, password) {
    return api.post('/user/retail_login', { email:btoa(username), password: btoa(password) })
  }

  register(username, password) {
    return api.post('/user/retail_register', {email: btoa(username), password: btoa(password)})
  }
}

export default new UserService()

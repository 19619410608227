<template>
  <div class="document-process-page page-content">
    <h1>Document Results</h1>
    <p v-if="!isDownloadComplete">Processing....</p>
    <div v-if="!isDownloadComplete" style="margin-bottom: 1rem;">
      <app-progress-bar />
    </div>
    <div v-if="errorMessage">
      <p>Error:</p>
      <p>{{ errorMessage }}</p>
    </div>
    <div v-if="files.length > 0">
       <transition-group name="fade" tag="div">
         <div v-for="file in files" :key="file.id" class="file-group">
           <div class="file-name">{{file.uploaded_file_name}}</div>
           <div v-for="translation in file.translatedTo" :key="translation.index" class="document-row">
             <div class="translation-header">{{file.original_language}} <span class="material-icons sync-icon">sync_alt</span> {{translation.language}}</div>
             <div class="document-table-header">
               <div>Name</div>
               <div>Type</div>
               <div>Description</div>
               <div>Created On</div>
             </div>
             <div v-if="translation.files && translation.files.length" style="width: 100%;">
               <div v-for="translatedFile in translation.files" :key="translatedFile.id" class="document-table">
                 <div class="document-name">{{file.name}}</div>
                 <div class="file-description">{{translatedFile.type}}</div>
                 <div class="file-description">{{translatedFile.description}}</div>
                 <div class="file-description">{{formatDate(translatedFile.date)}}</div>
                 <a :href="file.path" class="download-btn"><span>Download</span></a>
               </div>
             </div>
           </div>

         </div>
       </transition-group>
    </div>

  </div>

</template>

<script>
import DocumentService from '@/services/DocumentService'
import docxIcon from '@/assets/file_icons/docx-icon-x1.png'
import pdfIcon from '@/assets/file_icons/pdf-icon-x1.png'
import AppProgressBar from '@/components/controls/AppProgressBar'

export default {
  name: 'DocumentProcessPage',
  components: {
    AppProgressBar,
    docxIcon,
    pdfIcon
  },
  data () {
    return {
      timeoutId: null,
      isDownloadComplete: false,
      errorMessage: null,
      files: [],
      docxIcon,
      pdfIcon
    }
  },
  async created () {
    if (this.$route.params.transactionId && this.$route.params.email) {
      this.isDownloadComplete = false
      await this.getProcessPage()
    }
  },
  methods: {
    async getProcessPage () {
      try {
        const processPageResponse = await DocumentService.processDocuments(this.$route.params.transactionId, this.$route.params.email)
        const processPageData = processPageResponse.data
        const status = processPageData.status
        this.files = processPageData.files
        this.files.forEach(file => {
          file.translatedTo = []
          const translatedToKeys = Object.keys(file.translated_to)
          translatedToKeys.forEach((key, index) => {
            const translatedTo = {}
            translatedTo.index = index
            translatedTo.language = key
            translatedTo.files = file.translated_to[key]
            file.translatedTo.push(translatedTo)
          })
          delete file.translated_to
        })
        if (status === 200 && processPageData.completed) {
          clearTimeout(this.timeoutId)
          this.isDownloadComplete = true
        } else if (processPageData.status === 200) {
          this.timeoutId = setTimeout(async () => {
            await this.getProcessPage()
          }, 3000)
        }
      } catch (error) {
        console.log('Error:', error)
      }
    },
    getDocIcon (type) {
      if (type === 'docx') {
        return docxIcon
      }

      if (type === 'pdf') {
        return pdfIcon
      }
      return docxIcon
    },

    formatDate(dateStr) {
      const d = new Date(dateStr.replace(' ', 'T') + 'Z')
      const dd = d.getHours() >= 12 ? 'PM' : 'AM'
      const hh = d.getHours() < 10 ? d.getHours() + '0' : d.getHours()
      const mm = d.getMinutes() < 10 ? d.getMinutes() + '0' : d.getMinutes()
      const s = d.getSeconds() < 10 ? d.getSeconds() + '0' : d.getSeconds()
      return d.toLocaleDateString() + ' ' + hh + ':' + mm + ':' + s + ' ' + dd
    }
  }
}
</script>

<style scoped lang="scss">

@import "src/styles/app-styles";

.document-process-page {
  text-align: left;
  margin-top: 3rem;
}

.document-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  width: 100%;
  padding: 1rem;
  align-items: center;
}

.document-table-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  width: 100%;
  padding: 1rem;
  font-weight: bold;
  background-color: #eeee;
}

.document-process-page h1 {
  color: var(--button-and-link-colors);
}

.document-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 4px;
}

.document {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0.25rem;
}

.file-name {
  font-size: 24px;
  font-weight: bold;
  color: var(--main-theme-color);
  padding: 1rem;
  width: 100%;
  text-align: center;
}

.file-group {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid var(--main-theme-color);
  border-radius: 4px;
  margin-bottom: 1rem;
}

.translation-header {
  font-size: 16px;
  font-weight: bold;
  color: black;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.document-name {
}

.document-row .document .file-description {
  color: var(--color-primary);
}

.document-row .document .file-icon {
  width: 45px;
  height: 52px;
}

.language {
  font-weight: bold;
  color: var(--color-primary);
  font-size: 18px;
}

.download-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--button-and-link-colors);
  padding: 0.50rem 1rem;
  color: white !important;
  border-radius: 4px;
  text-decoration: none;
}

.download-btn:hover {
  text-decoration: none;
  opacity: 0.8;
}

.download-icon {
  font-size: 16px;
}

.sync-icon {
  color: var(--button-and-link-colors);
  margin: 0 40px;
}

</style>

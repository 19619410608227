import { api } from '@/utils/api'
import { StripeUtils } from '@/utils/StripeUtils'

class PaymentAndCheckoutService {
  getSummary (jwt) {
    return api.get(`/summary/${jwt}`)
  }

  verifyCoupon (data) {
    return api.post('/verify_coupon', { jwt: data.jwt, couponCode: data.couponCode })
  }

  createStripeSession (token, data) {
    data = data || {}
    const payload = {
      jwt: token,
      stripeSession: this.createStripePayload(token, data)
    }
    return api.post('/setup-stripe-session', payload)
  }

  applyCoupon (token, data) {
    const payload = {
      couponCode: data.couponCode,
      jwt: token,
      stripeSession: this.createStripePayload(token, data)
    }
    return api.post('/coupon', payload)
  }

  createStripePayload (token, data) {
    return StripeUtils.create({
      jwt: token,
      email: data.email,
      description: data.description,
      lineItems: data.lineItems ? data.lineItems : []
    })
  }
}

export default new PaymentAndCheckoutService()

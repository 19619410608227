import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  user: {
    library: null,
    libraryUserId: null,
    token: null,
    userName: null
  }
}

const mutations = {
  mutateUser (state, user) {
    state.user = {
      library: user.library,
      libraryUserId: user.libraryUserId,
      token: user.token,
      userName: user.userName
    }
  }
}

const actions = {
  setUser ({ commit }, user) {
    commit('mutateUser', user)
  },
  clearUser ({ commit }) {
    commit('mutateUser', {
      library: null,
      libraryUserId: null,
      token: null,
      userName: null
    })
  }
}

const getters = {
  user: state => state.user
}

export default {
  state,
  actions,
  mutations,
  getters
}

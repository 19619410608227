  <template>
    <div class="how-it-works page-content">
      <base-card>
        <template v-slot:header>
          <h2>{{ $t('how-it-works') }}</h2>
        </template>
        <template v-slot:content>
          <p>{{ $t('how-this-works-is-really-simple') }}</p>
          <p>{{ $t('do-you-have-a-document-pdf-excel-spreadsheet-that-you-need-to-translate') }}</p>
          <ol>
            <li>{{ $t('first-convert-it-to-pdf') }}</li>
            <li>{{ $t('second-fill-out-the-information-and-upload-the-document') }}</li>
            <li>{{ $t('add-your-email-complete-payment-if-necessary-and-then-sit-back-and-relax') }}</li>
          </ol>
          <p>
            {{ $t('in-about-5-minutes-you-will-receive-a-email-to-the-mail-address-entered-with-a-download-link') }}<br>
            {{  $t('download-and-enjoy-side-by-side-translation-of-your-document-into-the-target-language') }}<br>
            {{  $t('the-document-delivered-is-fully-enabled-for-modification-and-copying-where-ever-you-would-like') }}
            </p>
            <p>{{ $t('if-you-have-any-issues-reach-out-to') }} <a href="mailto:support@fluentconcepts.com">support@fluentconcepts.com</a></p>
        </template>
      </base-card>
    </div>
  </template>

<script>
import BaseCard from '@/components/BaseCard'
export default {
  name: 'HowItWorks',
  components: {
    BaseCard
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <div class="hide">
      <label for="inputUploadFile">Upload</label>
      <input type="file" class="form-control" id="inputUploadFile" ref="fileInput" placeholder="" @change="addFiles">
    </div>
    <md-button @click="triggerUpload" class="md-primary md-raised">Upload File</md-button>
    <div class="drop-zone-wrap" v-if="!uploadedFiles.length" @click="triggerUpload" @drop.prevent="addFiles" @dragover.prevent>
      <div class="drop-zone">
        <i class="material-icons cloud-upload-icon">cloud_upload</i>
        <div class="drop-zone-text">
          <p class="add-bottom-spacing">
            {{ $t('drag-a-file-here-to-upload-or') }}<br>
            <span class="select-drop-zone">{{ $t('select-a-file') }}</span> {{ $t('from-your-device') }}<br><br>
          </p>
        </div>
      </div>
    </div>
    <div class="uploaded-files" v-if="uploadedFiles.length" @drop.prevent="addFiles" @dragover.prevent>
      <div class="all-added-files">
        <div v-for="file in uploadedFiles" :key="file.name" class="added-file">
          <md-tooltip md-direction="top">{{file.name}}</md-tooltip>
          <img class="upload-file-icon" src="../assets/file_icons/pdf-icon-x2.png" :alt="file.name">
          <p class="upload-file-name">{{file.name}}</p>
          <md-button @click="removeFile(file)" class="md-icon-button md-accent">
            <md-icon>close</md-icon>
          </md-button>
        </div>
      </div>
    </div>
    <div class="flex-row-container">
      <p>
        <span class="muted-text"> {{ $t('pdf-only-maximum-file-size-100mb') }} </span>
      </p>
    </div>
    <div class="invalid-feedback">
      <div v-if="inValidFiles.nonSupportedFiles.length">
        <p>The following files are not supported:</p>
        <ul>
          <li v-for="(invalidFile, index) in inValidFiles.nonSupportedFiles" :key="index"> {{ invalidFile.name }}</li>
        </ul>
      </div>
      <div v-if="inValidFiles.maximumFileSize.length">
        <p>The following files are too large:</p>
        <ul>
          <li v-for="(invalidFile, index) in inValidFiles.maximumFileSize" :key="index">{{ invalidFile.name }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import FileService from '@/services/FileService'

const validFileTypes = [
  'application/pdf', // PDF
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
  'application/msword', // DOC
  'application/vnd.ms-powerpoint', // PPT
  'application/vnd.ms-excel', // XLS
  'application/octet-stream' // documents created in libreoffice
]

const MB = 1000
const KB = 0.001

export default {
  name: 'FileUploadComponent',
  data () {
    return {
      uploadedFiles: [],
      inValidFiles: {
        nonSupportedFiles: [],
        maximumFileSize: []
      }
    }
  },
  methods: {
    addFiles (evt) {
      const files = evt.dataTransfer ? evt.dataTransfer.files : evt.target.files

      files.forEach(file => {
        let isFileValid = true
        if (!this.isFileSupported(file)) {
          this.inValidFiles.nonSupportedFiles.push(file)
          isFileValid = false
        }

        if (isFileValid && !this.isFileSizeValid(file)) {
          this.inValidFiles.maximumFileSize.push(file)
          isFileValid = false
        }

        if (isFileValid) {
          const uploadedFile = FileService.createFile(file)
          this.$emit('file:added', uploadedFile)
          this.uploadedFiles.push(uploadedFile)
          // file has been uploaded to server, clear the file input
          // NOTE: if this is not cleared then removing and adding a file again will NOT work
          // due to the file input still showing it in the list.
          this.$refs.fileInput.value = ''
        }
      })
      this.$emit('multiple-files:added', this.uploadedFiles)
    },
    triggerUpload () {
      this.$refs.fileInput.click()
    },
    removeFile (file) {
      if (this.uploadedFiles.length) {
        const fileToRemove = this.uploadedFiles.find( (f) => f.id === file.id)
        if (fileToRemove) {
          this.$emit('file:removed', { file: fileToRemove, handler: this.removeFileDone})
        }
      }
    },
    removeFileDone (file) {
      if (this.uploadedFiles.length) {
        const fileIndex = this.uploadedFiles.findIndex( (f) => f.id === file.id)
        this.uploadedFiles.splice(fileIndex, 1)
      }
    },
    isFileSupported (file) {
      return validFileTypes.includes(file.type)
    },
    isFileSizeValid (file) {
      return file.size <= MB / KB
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/app-styles";

  .hide {
    display: none;
  }

  .drop-zone-wrap {
    background: $light_gray;
    border: 1px dashed $light_gray_border;
    border-radius: $border_radius;
    color: $light_text;
    cursor: move;
    position: relative;
  }
  .drop-zone, .select-drop-zone {
    margin: 0 auto;
    text-align: center;
  }
  .drop-zone {
    padding: 80px 20px;
  }
  .drop-zone.in {
    background: #f0f0f0;
    color: #999;
  }
  .select-drop-zone {
    cursor: pointer;
    color: lighten($base_blue, 20%);
  }
  .drop-zone .fa-cloud-upload-alt{
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .drop-zone-wrap .overlay-loader {
    background: rgba(255, 255, 255, 0.7);
    bottom: 0;
    height: 100%;
    left: 0;
    padding: 10%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10010;
  }
  .drop-zone-text p:last-child{
    margin-bottom: 0;
  }
  .uploaded-files {
    font-size: 1.2rem;
    background: $light_blue;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    border: 1px solid $light_gray_border;
    border-radius: $border_radius;
  }

  .uploaded-files .upload-file-icon {
    margin-right: 0;
  }
  .uploaded-files .upload-file-name {
    font-size: 12px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .uploaded-files .progress {
    margin-top: 12px;
  }
  .cloud-upload-icon {
    font-size: 60px;
  }

  .all-added-files {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
  }

  .added-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem 1rem 1rem;
    border-radius: 1rem;
    margin: .25rem;
  }

  .invalid-feedback {
    color: red;
  }

  .form-group-error {
    border: none !important;
  }

  .form-group-error .drop-zone-wrap {
    border: 1px solid red;
  }
</style>

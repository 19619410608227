<template>
  <div class="translation-component">
    <div class="header-controls">
      <div>
        <span class="translation-component-label" v-if="!isMultiselect"><span class="field-label">{{label}}</span></span>
        <div  class="translation-component-label" v-if="selectedTranslations && isMultiselect">
          <span class="field-label">{{label}}:</span>
          <span class="translations-count">{{selectedTranslations.length}}</span>
        </div>
      </div>
      <md-button @click="showModal" class="add-button md-primary md-raised"><md-icon>add</md-icon></md-button>
    </div>
    <div class="translation-component-input">
      <div v-if="selectedTranslations.length && isMultiselect" class="language-container">
        <div v-for="language in selectedTranslations" :key="language.id" class="language-selected">
          <div class="language-flag" :class="getFlagIcon(language)"></div>
          <div class="language-name">{{language.name}}</div>
          <div class="close-button" @click="removeLanguage(language)">
            <div class="material-icons close-button-icon">close</div>
          </div>
        </div>
      </div>
      <div v-if="selectedTranslation && !isMultiselect" class="language-container">
        <div class="language">
          <div class="language-flag" :class="getFlagIcon(selectedTranslation)"></div>
          <div class="language-name">{{selectedTranslation.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TranslationModal from '@/components/modals/TranslationModal'
import { mapMutations } from 'vuex'
export default {
  name: 'TranslationComponent',
  props: {
    label: String,
    isMultiselect: Boolean
  },
  data () {
    return {
      languages: []
    }
  },
  computed: {
    selectedTranslations () {
      return this.$store.getters.selectedTranslations
    },
    selectedTranslation () {
      return this.$store.getters.selectedTranslation
    }
  },
  methods: {
    ...mapMutations({
      removeSelectedTranslation: 'removeSelectedTranslation'
    }),
    async showModal () {
      this.$modal.show(TranslationModal, {
        isMultiselect: this.isMultiselect
      }, {
        name: 'TranslationModal',
        adaptive: true,
        scrollable: true,
        height: 'auto'
      })
    },
    getFlagIcon (language) {
      return 'flag-icon flag-icon-' + language.flagCode
    },
    removeLanguage (language) {
      this.removeSelectedTranslation(language)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/flag-icon-css-master/css/flag-icon.css";
@import "../styles/app-styles.scss";
@import "../styles/transitions.scss";

.add-button {
  min-width: 24px;
  background-color: var(--button-and-link-colors) !important;
}

.field-label {
  color: var(--color-primary-30);
  transition: all .2s ease;
}

.translations-count {
  font-weight: bold;
  margin-left: 5px;
}

.header-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.translation-component {
  width: 100%;
  background-color: rgb(245,245,245);
  border-bottom: 1px solid rgba(0,0,0,0.1)
}

.translation-component-input {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  //border-bottom: solid 1px #c6c9cb;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  justify-content: space-between;
}

.translation-component-label {
  text-align: left;
  width: 100%;
}

.language-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 0 4px;
  overflow: auto;
  min-height: 56px;
  max-height: calc(56px * 4.6);
}

.language, .language-selected {
  background-color: #fff;
  padding: 6px 10px 6px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: .25rem;
  flex: 1 1 150px;
}

.language-selected {
  max-width: 150px;
}

.language-flag {
  flex: 1;
  margin: 0 6px;
}

.language-name {
  flex: 2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.close-button {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}

.close-button:hover {
  background-color: rgba(0,0,0,0.2);
}

.close-button-icon {
  font-size: 12px;
  color: rgba(0,0,0,0.2);
}

.close-button-icon {
  color: rgba(255,255,255,0.8)
}
</style>

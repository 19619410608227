<template>
  <md-menu md-size="medium" md-align-trigger>
    <md-button md-menu-trigger class="flag-button"><span :class="flagIcon"></span></md-button>

    <md-menu-content v-if="languages.length">
      <md-menu-item v-for="language in languages" :key="language.id" @click="changeLanguage(language)">
        <span>{{language.name}}</span><span :class="getFlagIcon(language)"></span>
      </md-menu-item>
    </md-menu-content>
  </md-menu>
</template>

<script>
import { languages } from '@/i18n'

export default {
  name: 'LanguageDropdown',
  data () {
    return {
      selectedLanguage: null,
      languages: [
        {
          active: true,
          id: 1,
          code: 'en',
          flagCode: 'us',
          localization: 'en_US',
          name: 'English'
        },
        {
          active: true,
          id: 2,
          code: 'fr',
          flagCode: 'fr',
          localization: 'fr_FR',
          name: 'French'
        },
        {
          active: true,
          id: 3,
          code: 'it',
          flagCode: 'it',
          localization: 'it_IT',
          name: 'Italian'
        },
        {
          active: true,
          id: 4,
          code: 'pt',
          flagCode: 'pt',
          localization: 'pt_BR',
          name: 'Portuguese'
        },
        {
          active: true,
          id: 5,
          code: 'es',
          flagCode: 'es',
          localization: 'es_ES',
          name: 'Spanish (CS)'
        },
        {
          active: true,
          id: 6,
          code: 'es',
          flagCode: 'la',
          localization: 'es_MX',
          name: 'Spanish (LA)'
        }
      ],
      supportedLanguages: languages
    }
  },
  created () {
    const selected = this.lang ? this.lang : this.languages[0].localization
    this.setSelectedLanguage(selected)
  },
  computed: {
    flagIcon () {
      if (this.selectedLanguage) {
        const flagCode = this.selectedLanguage.flagCode
        return 'flag-icon flag-icon-' + flagCode
      }
      return ''
    },
    lang: {
      get () {
        return this.$store.getters.locale
      },
      set (value) {
        this.$store.dispatch('changeLocale', value)
      }
    }
  },
  methods: {
    getFlagIcon (language) {
      if (this.selectedLanguage) {
        return 'flag-icon flag-icon-' + language.flagCode
      }
      return ''
    },
    changeLanguage (language) {
      this.setSelectedLanguage(language.localization)
    },
    setSelectedLanguage (localization) {
      this.selectedLanguage = localization ? this.languages.find(language => language.localization === localization) : this.languages[0]
      this.lang = localization
      this.$emit('selected:language', this.selectedLanguage)
    }
  }
}
</script>

<style scoped>
@import "../css/flag-icon-css-master/css/flag-icon.css";

.md-menu-item {
  cursor: pointer;
  pointer-events: auto;
}

.flag-button {
  min-width: 0;
}
</style>

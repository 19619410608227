import Vue from 'vue'
import Vuex from 'vuex'
import LanguageService from '@/services/LanguageService'

Vue.use(Vuex)

const state = {
  translations: [],
  selectedTranslation: null,
  selectedTranslations: []
}

const mutations = {
  setTranslations (state, translations) {
    state.translations = translations
  },
  setSelectedTranslation (state, translation) {
    state.selectedTranslation = translation
  },
  setSelectedTranslations (state, translations) {
    state.selectedTranslations = translations
  },
  removeSelectedTranslation (state, translation) {
    const index = state.selectedTranslations.findIndex((language) => {
      return language.name === translation.name
    })

    state.selectedTranslations.splice(index, 1)
  }
}

const actions = {
  async getLanguages ({ commit }) {
    const response = await LanguageService.getLanguages()
    if (response.data.status === 'success') {
      commit('setTranslations', response.data.data)
    }
  }
}

const getters = {
  translations: state => state.translations,
  selectedTranslation: state => state.selectedTranslation,
  selectedTranslations: state => state.selectedTranslations
}

export default {
  state,
  actions,
  mutations,
  getters
}

export const AppUtils = {
  showResponseError (error) {
    const exception = error.response.data.exception
    let errorMessage = error.response.data.message + '\n\n'
    errorMessage += 'Exception:\n\n'
    for (let i = 0; i < exception.length; i++) {
      errorMessage += `Code: ${exception[i].code}\n`
      errorMessage += `File: ${exception[i].file}\n`
      errorMessage += `Line: ${exception[i].line}\n`
      errorMessage += `Message: ${exception[i].message}\n`
      errorMessage += `Type: ${exception[i].type}\n`
    }
    alert(errorMessage)
  },

  showError (error) {
    let errorMessage = 'Error:\n\n'
    errorMessage += error + '\n\n'
    alert(errorMessage)
  },

  getHost () {
    return window.location.host
  }
}

<template>
<div class="what-we-do-section">
  <div class="icon-container">
    <img v-if="icon === 'document'" src="../assets/site_icons/document_icon.png" alt="convert icon"/>
    <img v-if="icon === 'convert'" src="../assets/site_icons/convert_icon.png" alt="convert icon"/>
    <img v-if="icon === 'download'" src="../assets/site_icons/download_icon.png" alt="convert icon"/>
  </div>
  <h4>{{label}}</h4>
  <p class="description"><slot></slot></p>
</div>
</template>

<script>
export default {
  name: 'WhatWeDoSection',
  props: {
    icon: String,
    label: String
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/app-styles";
.what-we-do-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-size: 24px;
    font-weight: bold;
    color: var(--section-one-title-color);
  }

  .description {
    max-width: 237px;
    font-size: 18px;
  }
}

.icon-container {
  border-radius: 50px;
  width: 100px;
  height: 100px;
  background-color: var(--section-icon-colors);
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>

<template>
<div class="site-logo">
  <img v-if="siteLogo === 'fluent'" :src="logo" alt="Fluent Concepts">
  <img v-if="siteLogo === 'legal'" :src="logoLegal" alt="Fluent Concepts - Legal">
  <img v-if="siteLogo === 'medical'" :src="logoMedical" alt="Fluent Concepts - Medical">
</div>
</template>

<script>
import logo from '@/assets/Instant-Translation-Logo.png'
import logoLegal from '@/assets/Legal-Instant-Translation-Logo.png'
import logoMedical from '@/assets/Medical-Instant-Translation-Logo.png'
export default {
  name: 'SiteLogo',
  props: {
    logoType: {
      type: String
    }
  },
  data () {
    return {
      logo,
      logoLegal,
      logoMedical
    }
  },
  computed: {
    siteLogo () {
      return this.$store.getters.appStyles.logo
    }
  }
}
</script>

<style scoped>
  .site-logo img {
    max-height: 64px;
  }
</style>

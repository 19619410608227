<template>
  <md-card class="base-card">
    <md-card-header>
      <div class="base-card-header">
        <slot name="header"></slot>
      </div>
      <hr>
    </md-card-header>
    <md-card-content>
      <div class="card-content">
        <slot name="content"></slot>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: 'BaseCard'
}
</script>

<style lang="scss" scoped>
@import "../styles/app-styles";

.md-card {
  box-shadow: none;
  width: 100%;
  margin: 0 auto;
}

.base-card {
  margin-top: 3rem;
}

::v-deep .base-card-header h2 {
  color: var(--button-and-link-colors) !important;
}

.base-card hr {
  background-color: var(--button-and-link-colors);
}

.card-content {
  text-align: left;
  color: var(--main-font-color);
  font-size: 16px;
}
</style>

<template>
  <div id="app" :class="[{'medical': isMedical}, {'legal': isLegal}]">
    <div class="loader-overlay" v-if="loading">
      <div><md-progress-spinner class="progress-spinner" :md-diameter="100" :md-stroke="10" md-mode="indeterminate"/> </div>
    </div>
    <app-header @open-login="showLogin=true" @logout="logoutUser" @open-register="showRegister=true" />
    <login-modal v-model="showLogin" @close="showLogin=false"/>
    <register-modal v-model="showRegister" @close="showRegister=false"/>
    <div class="main-router-container">
      <transition name="fade">
        <router-view @open-login="showLogin=true"/>
      </transition>
    </div>
    <app-footer @open-login="showLogin=true" />
  </div>
</template>
<script>
import backgroundUrl from '@/assets/fluent_page_bg.jpg'
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'
import { mapMutations, mapActions } from 'vuex'
import LoginModal from '@/components/modals/LoginModal'
import RegisterModal from '@/components/modals/RegisterModal'
export default {
  name: 'app',
  components: {
    RegisterModal,
    LoginModal,
    AppFooter,
    AppHeader
  },
  data () {
    return {
      backgroundUrl: backgroundUrl,
      host: '',
      showLogin: false,
      showRegister: false
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    isMedical () {
      return this.host === 'medical.instanttranslation.com'
    },

    isLegal () {
      return this.host === 'legal.instanttranslation.com'
    }
  },
  created () {
    this.host = window.location.hostname
    // this.host = 'medical.instanttranslation.com'
    // this.host = 'legal.instanttranslation.com'

    this.setAppStyles({
      logo: this.getLogo(),
      type: this.getType()
    })
  },
  methods: {
    ...mapMutations({
      setAppStyles: 'setAppStyles'
    }),
    ...mapActions({
      clearUser: 'clearUser'
    }),
    getLogo () {
      let logo = 'fluent'
      if (this.host === 'medical.instanttranslation.com') {
        logo = 'medical'
      }

      if (this.host === 'legal.instanttranslation.com') {
        logo = 'legal'
      }
      return logo
    },
    getType () {
      let siteType = 'fluent'
      if (this.host === 'medical.instanttranslation.com') {
        siteType = 'medical'
      }

      if (this.host === 'legal.instanttranslation.com') {
        siteType = 'legal'
      }
      return siteType
    },
    logoutUser () {
      this.clearUser()
    }
  }
}
</script>
<style lang="scss">
@import "styles/app-styles";
@import "css/flag-icon-css-master/css/flag-icon.css";
#app:before {
  content: '';
  display:block;
  height: 126px;
}
#app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  color: var(--main-font-color);

  .app-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .main-router-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .loader-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
    z-index: 9000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .progress-spinner .md-progress-spinner-circle {
    stroke: var(--button-and-link-colors);
  }
}
</style>

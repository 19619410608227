<template>
  <div class="home">
    <section class="hero">
      <div class="content">
        <div class="hero-container">
          <h1>{{ $t('fluent-document-translation')  }}</h1>
          <p class="hero-description">{{ $t('quickly-and-easily-translate-any-pdf-document-using-the-latest-in-artificial-intelligence-and-machine') }}</p>
          <translate-now-button />
        </div>
        <div class="hero-image">
          <img id="heroImage" :src="heroImage" alt="hero illustration" />
        </div>
      </div>
    </section>
    <section class="what-we-do section-one">
      <div class="content">
        <h4>{{ $t('what-we-do-for-you') }}</h4>
        <div class="what-we-do-sections">
          <what-we-do-section :label="$t('Document')" icon="document">
            {{ $t('Select the document you want translated and upload it to our system in PDF format.') }}
          </what-we-do-section>
          <what-we-do-section :label="$t('Convert')" icon="convert">
            {{ $t('Once the upload of your document is complete, our translators go to work, converting your document into the language of your choice.') }}
          </what-we-do-section>
          <what-we-do-section :label="$t('Download')" icon="download">
            {{ $t('When complete, one simple click is all you need in order to download your freshly translated document!') }}
          </what-we-do-section>
        </div>
      </div>
    </section>
    <section class="section-two">
      <div class="content">
        <div class="section-two-layout">
          <img id="sectionTwoImage" :src="sectionImage" alt="Section Image" />
          <div>
            <h1>{{ $t('we-focus-on-the-main-issue-of-a-shrinking-world') }}</h1>
            <p>{{ $t('Technology has united the world in many ways, and the need to understand each other, no matter what language we speak, has never been more crucial. Fluent Concepts understands this, breaking these barriers by creating the opportunity to communicate ideas and information with precision and clarity to anyone, regardless of country or continent.') }}</p>
            <base-button to="/about" :css-outline="true">{{ $t('about-us') }}</base-button>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>

import TranslateNowButton from '@/components/TranslateNowButton'
import WhatWeDoSection from '@/components/WhatWeDoSection'
import FluentHeroImage from '@/assets/fluent-hero-illustration.png'
import MedicalHeroImage from '@/assets/medical-hero-illustration.png'
import LegalHeroImage from '@/assets/legal-hero-illustration.png'
import FluentSectionImage from '@/assets/clipboard-with-doc.png'
import MedicalSectionImage from '@/assets/medical-charts-illustration.png'
import LegalSectionImage from '@/assets/legal-lawyer-illustration.png'
import BaseButton from '@/components/controls/base/BaseButton'
export default {
  name: 'Home',
  components: { WhatWeDoSection, TranslateNowButton, BaseButton },
  data () {
    return {
      FluentHeroImage,
      MedicalHeroImage,
      LegalHeroImage,
      FluentSectionImage,
      MedicalSectionImage,
      LegalSectionImage
    }
  },
  computed: {
    heroImage () {
      const siteType = this.$store.getters.appStyles.type
      let image = FluentHeroImage
      if (siteType === 'legal') {
        image = LegalHeroImage
      }
      if (siteType === 'medical') {
        image = MedicalHeroImage
      }
      return image
    },
    sectionImage () {
      const siteType = this.$store.getters.appStyles.type
      let image = FluentSectionImage
      if (siteType === 'legal') {
        image = LegalSectionImage
      }
      if (siteType === 'medical') {
        image = MedicalSectionImage
      }
      return image
    }
  }
}
</script>

<style scoped lang="scss">

$hero_height: 652px;
@import "../styles/app-styles";
 section {
   min-height: 0;
   background-color: #ffffff;
 }

 section .content {
   display: flex;
   align-items: center;
   width: 100%;
   height: 100%;
   min-height: 100%;
   margin: 0 auto;
   overflow: hidden;
 }

 section.hero {
   background-color: var(--hero-background-color);
 }

 section.hero .content {
   display: grid;
   grid-template-columns: auto auto;
   grid-template-rows: auto;
   grid-gap: 1rem;
   align-items: center;
 }

 section.hero .hero-container {
   text-align: left;
 }

 section.hero .hero-container h1 {
   font-style: normal;
   font-weight: 900;
   font-size: 64px;
   line-height: 75px;
   color: var(--hero-h1-color);
   text-align: left;
   margin: 0;
 }

 section.hero .hero-container .hero-description {
   font-size: 24px;
   line-height: 32px;
   color: var(--hero-font-color);
 }

 section.hero .hero-container .translate-now-button {
  margin-top: 40px;
  margin-left: 0;
 }

 section.section-one {
   background-color: var(--section-one-background-color);
 }

 section.section-one .content {
   display: flex;
   flex-direction: column;
 }

 section.section-two {
   background-color: var(--section-two-background-color);
 }

 section.section-two .section-two-layout {
   display: grid;
   grid-template-columns: auto auto;
   grid-gap: 2rem;
   text-align: left;
   align-items: center;
   height: 100%;
 }

 section.section-two .section-two-layout h1 {
   color: var(--section-two-h1-color);
   font-size: 48px;
   font-weight: bold;
 }

 section.section-two .section-two-layout p {
   color: var(--section-two-font-color);
   font-size: 20px;
   margin-bottom: 23px;
 }

 section.what-we-do {

   h4 {
     color: var(--section-one-title-color);
     font-size: 36px;
     font-weight: 500;
     margin: 1rem 0 3rem;
   }

   .what-we-do-sections {
     display: grid;
     color: var(--section-one-font-color)
   }

   .what-we-do-section h4 {
     color: var(--section-one-font-color);
   }
 }

/* Extra small devices (phone, 320px to 576px) */
@media only screen and (max-width: $media_breakpoint_sm) and (min-width: $media_breakpoint_xsm) {
  .what-we-do-sections {
    grid-template-columns: auto;
    grid-gap: 0;
  }

  section.section-two .section-two-layout {
    grid-template-columns: auto;
    justify-items: center;
  }

  section .content {
    justify-items: center;
  }

  section.hero .content {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }

  section.hero .hero-container {
    grid-row: 2;
    text-align: center;

    h1 {
      font-size: 32px;
      line-height: 32px;
      text-align: center;
    }
  }

  section.hero .hero-image {
    grid-row: 1;
    max-width: 50vw;
    max-height: 50vh;
  }
}

/* Medium devices (landscape tables, 576px to 768px) */
@media only screen and (max-width: $media_breakpoint_md) and (min-width: $media_breakpoint_sm) {
  section.hero .content {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-content: center;

    .hero-container {
      grid-row: 2;
    }

    .hero-image {
      grid-row: 1;
    }
  }

  .what-we-do-sections {
    grid-template-columns: auto;
    grid-gap: 0;
  }
}

/* Large devices (tablets and phones, 768px to 992px) */
@media only screen and (max-width: $media_breakpoint_lg) and (min-width: $media_breakpoint_md) {
  section.hero {
    display: flex;
    flex-direction: column;
  }

  section.hero .content {
    width: 100%;
    max-width: 100%;
    font-size: 5vw;
  }

  section.hero .content h1 {
    font-size: 48px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: $media_breakpoint_lg) {
  .what-we-do-sections {
    grid-gap: 16rem;
    grid-template-columns: auto auto auto;
  }

  section.hero .content .hero-container, section.hero .content .hero-image {
    grid-row: 1;
  }

  section.hero .content .hero-image {
    max-width: 100%;
    max-height: 100%;
  }

  section.section-two .section-two-layout {
    grid-template-columns: auto auto;
  }
}
</style>

<template>
  <div
    class="app-progress-bar"
  >
    <md-progress-bar md-mode="indeterminate"></md-progress-bar>
  </div>
</template>

<script>
export default {
    name: 'AppProgressBar'
}
</script>

<style scoped lang="scss">
@import "src/styles/app-styles.scss";
.app-progress-bar {
  width: 100%;
  color: var(--main-theme-color);
}
</style>

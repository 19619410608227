import { api } from '@/utils/api'

class LanguageService {
  async getSiteLanguages () {
    return await api.get('/site_languages/2')
  }

  async getLanguages () {
    return await api.get('/languages')
  }
}
export default new LanguageService()

<template>
<div class="page-content">
  <h1>Checkout</h1>
  <form id="checkoutSummaryForm" novalidate.prevent>
    <div id="summaryInfo">
      <div>
        <h3>{{ $t('Summary') }}</h3>
        <div v-if="!loadingSummary">
          <div class="summary-field"><span class="summary-item">{{ $t('Document name') }}:</span><span class="summary-value">{{ documentName }}</span></div>
          <div v-for="translateTo in translations" :key="translateTo.id" class="summary-field"><span class="summary-item">{{ $t('Translate') }}:</span><span class="summary-value">{{ translateTo.from }} <span class="material-icons sync-icon">arrow_right_alt</span> {{ translateTo.to }}</span></div>
          <div class="summary-field"><span class="summary-item">{{ $t('Page count') }}:</span><span class="summary-value">{{ pageCount }}</span></div>
          <div class="summary-field"><span class="summary-item">{{ $t('Language count') }}:</span><span class="summary-value">{{ languageCount }}</span></div>
        </div>
        <div v-else>
          <p>{{ $t('loading summary...') }}</p>
        </div>
      </div>
    </div>
    <div class="coupon-field" v-if="total > 0">
      <base-input-wrapper>
        <base-input :label="$t('Enter coupon code')" v-model.trim="coupon" />
      </base-input-wrapper>
      <base-button @click="applyCoupon">{{ $t('Apply') }}</base-button>
      <base-button v-if="coupon !== null && coupon !== ''" :css-outline="true" class="clear-button">{{ $t('Clear') }}</base-button>
    </div>

    <div class="total-cost">
      <div v-if="hasCouponBeenApplied" class="summary-field">
        <span class="summary-item">{{ $t('Total') }}:</span>
        <span class="summary-value">${{formatTotal}}</span>
      </div>
      <div v-if="hasCouponBeenApplied" class="summary-field">
        <span class="summary-item">{{$t('Coupon Discount')}}: {{coupon}} </span>
        <span class="summary-value">{{couponDiscount}}</span>
      </div>
      <div class="summary-field">
        <span class="summary-item">{{ $t('Grand Total') }}:</span>
        <span class="summary-value">${{formatGrandTotal}}</span>
      </div>
    </div>
    <div class="form-actions">
      <base-button v-if="total === 0" @click="goToDocumentComplete">{{ $t('Process for Free') }}</base-button>
      <base-button v-else @click="payWithCard">{{ $t('pay-with-card') }}</base-button>
    </div>
  </form>
</div>
</template>

<script>
import baseInputWrapper from '@/components/controls/base/BaseInputWrapper'
import baseInput from '@/components/controls/base/BaseInput'
import BaseButton from '@/components/controls/base/BaseButton'
import PaymentAndCheckoutService from '@/services/PaymentAndCheckoutService'
import { storage } from '@/utils/storage'
import { mapMutations } from 'vuex'
import { AppUtils } from '@/utils/AppUtils'

export default {
  name: 'CheckoutSummary',
  components: {
    BaseButton,
    baseInputWrapper,
    baseInput
  },
  data () {
    return {
      documentName: 'Error',
      total: 0,
      grandTotal: 0,
      pageCount: 0,
      productId: null,
      stripe: null,
      stripePaymentSession: {},
      hasCouponBeenApplied: false,
      couponDiscount: 0,
      translations: [
        {
          id: 0,
          from: 'undefined',
          to: 'undefined'
        }
      ],
      coupon: '',
      hasBeenSubmitted: false
    }
  },
  computed: {
    languageCount () {
      return this.translations.length
    },
    loadingSummary () {
      return this.$store.getters.loading
    },
    formatTotal () {
      return this.total.toFixed(2)
    },
    formatGrandTotal () {
      return this.grandTotal.toFixed(2)
    }
  },
  async created () {
    try {
      const jwt = storage.getValue('jwt')

      this.isLoading(true)
      // get the checkoutSession data
      const checkoutResponse = await PaymentAndCheckoutService.getSummary(jwt)
      const checkoutResults = checkoutResponse.data
      if (checkoutResults) {
        this.total = checkoutResults.total_price
        this.grandTotal = checkoutResults.grand_total
        this.documentName = checkoutResults.document_name
        this.pageCount = checkoutResults.total_pages
        this.translations = []
        const mainLanguage = checkoutResults.source_language
        checkoutResults.target_languages.forEach((language, index) => {
          this.translations.push({ id: index, from: mainLanguage, to: language.name })
        })

        if (this.total > 0) {
          await this.setupStripeSession()
        }
      }
    } catch (error) {
      alert(error)
    } finally {
      this.isLoading(false)
    }
  },
  methods: {
    ...mapMutations({
      isLoading: 'isLoading'
    }),
    async setupStripeSession () {
      if (this.translations.length) {
        try {
          this.isLoading(true)
          const checkoutSession = this.$store.getters.checkoutSession
          const host = 'https://' + AppUtils.getHost()
          const payload = {
            email: checkoutSession.email,
            description: this.generateDescription(),
            lineItems: [
              {
                name: 'Fluent Document Translation',
                description: this.generateDescription(),
                images: [host + '/images/file_icons/pdf-icon-x2.png'],
                amount: Number(this.grandTotal + '00'),
                currency: 'usd',
                quantity: 1,
              }
            ],
            productId: this.productId
          }
          const stripeSessionResponse = await PaymentAndCheckoutService.createStripeSession(checkoutSession.jwt, payload)
          this.stripePaymentSession = stripeSessionResponse.data.payment_session
          if (stripeSessionResponse && stripeSessionResponse.data && stripeSessionResponse.data.stripe_publishable_key) {
            const responseData = stripeSessionResponse.data
            if (window.Stripe) {
              this.stripe = window.Stripe(responseData.stripe_publishable_key)
            }
          }
          this.isLoading(false)
        } catch (error) {
          AppUtils.showResponseError(error)
        } finally {
          this.isLoading(false)
        }
      }
    },
    async applyCoupon () {
      try {
        this.isLoading(true)
        const checkoutSession = this.$store.getters.checkoutSession
        const host = AppUtils.getHost()
        const payload = {
          couponCode: this.coupon,
          email: checkoutSession.email,
          description: this.generateDescription(),
          lineItems: [
            {
              name: 'Fluent Document Translation',
              description: this.generateDescription(),
              images: [host + '/images/file_icons/pdf-icon-x2.png'],
              amount: this.grandTotal,
              currency: 'usd',
              quantity: 1
            }
          ]
        }
        const couponResponse = await PaymentAndCheckoutService.applyCoupon(checkoutSession.jwt, payload)
        if (couponResponse.data.status === 'success') {
          const couponResponseData = couponResponse.data.data
          this.stripePaymentSession = couponResponseData.payment_session
          this.hasCouponBeenApplied = true
          this.grandTotal = Number(couponResponseData.grand_total)
          this.total = couponResponseData.total_price
          this.couponDiscount = '- $' + Number(this.total - this.grandTotal).toFixed(2)
        }
        this.isLoading(false)
      } catch (error) {
        AppUtils.showResponseError(error)
      } finally {
        this.isLoading(false)
      }
    },
    async payWithCard () {
      // check coupon is valid if one is set
      if (this.coupon) {
        this.isLoading(true)
        const couponCodeVerifyResponse = await PaymentAndCheckoutService.verifyCoupon({
          jwt: storage.getValue('jwt'),
          couponCode: this.coupon
        })
        const couponCodeVerifyResponseData = couponCodeVerifyResponse.data
        if (couponCodeVerifyResponseData.status === 'success') {
          this.redirectToStripe(this.stripePaymentSession.id)
        } else if (couponCodeVerifyResponseData.status === 'error') {
          AppUtils.showError(couponCodeVerifyResponseData.message)
        }
        this.isLoading(false)
      } else {
        this.redirectToStripe(this.stripePaymentSession.id)
      }
    },
    redirectToStripe (sessionId) {
      if (this.stripe && this.stripe.redirectToCheckout) {
        try {
          this.stripe.redirectToCheckout({ sessionId: sessionId })
        } catch (error) {
          alert(error)
        }
      }
    },
    generateDescription () {
      let description = 'Translate a document from ' + this.translations[0].from + ' to '
      this.translations.forEach((translation) => {
        description += translation.to + ','
      })
      description = description.substr(0, description.length - 1)
      return description
    },
    async goToDocumentComplete () {
      const jwt = storage.getValue('jwt')
      const encodedEmail = this.$store.getters.checkoutSession.email ? btoa(this.$store.getters.checkoutSession.email) : null
      if (jwt && encodedEmail) {
        await this.$router.push({
          name: 'DocumentComplete',
          params: {
            jwt: jwt,
            email: encodedEmail
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/app-styles";

h1 {
  padding: 3rem 0;
  color: var(--button-and-link-colors);
}

#checkoutSummaryForm {
  display: flex;
  flex-direction: column;

  #summaryInfo {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--main-theme-color);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h3 {
      color: var(--main-theme-color);
    }

    > div {
      display: flex;
      flex-direction: column;
      min-width: 250px;
      width: 100%;
      text-align: left;
      padding: 5rem;
    }
  }

  .summary-field {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 1rem 0;
  }

  .summary-item {
    color: rgba(0,0,0,.3);
    font-weight: bold;
  }

  .summary-value {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .sync-icon {
    color: var(--button-and-link-colors);
  }

  .coupon-field {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    border: 1px solid var(--main-theme-color);
    padding: 2rem 5rem;
    margin-bottom: 2rem;

    .base-input-wrapper {
      margin-bottom: 0 !important;
    }

    .base-input {
      min-width: 150px;
    }
  }

  .total-cost {
    display: flex;
    flex-direction: column;
    padding: 2rem 5rem;
    border: 1px solid var(--main-theme-color);
  }

  .apply-button {
    background-color: transparent;
    border: 2px solid var(--button-and-link-colors);
    color: var(--button-and-link-colors) !important;
  }

  .form-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 2rem 0;
  }
}
</style>

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import HowItWorks from '../views/HowItWorks.vue'
import Login from '../views/Login.vue'
import TranslateNow from '../views/TranslateNow.vue'
import CheckoutSummary from '../views/CheckoutSummary.vue'
import CheckoutSuccess from '../views/CheckoutSuccess.vue'
import DocumentProcessPage from '../views/DocumentProcessPage.vue'
import DocumentComplete from '../views/DocumentComplete.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/how-it-works',
    name: 'HowItWorks',
    component: HowItWorks
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/translate-now',
    name: 'TranslateNow',
    component: TranslateNow
  },
  {
    path: '/checkout-summary',
    name: 'CheckoutSummary',
    component: CheckoutSummary
  },
  {
    path: '/checkout-success',
    name: 'CheckoutSuccess',
    component: CheckoutSuccess
  },
  {
    path: '/document-complete/:jwt/:email',
    name: 'DocumentComplete',
    component: DocumentComplete
  },
  {
    path: '/document-process-page/:transactionId/:email',
    name: 'DocumentProcessPage',
    component: DocumentProcessPage
  }
]

const router = new VueRouter({
  routes
})

export default router

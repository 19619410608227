<template>
  <div class="about page-content">
    <base-card>
      <template v-slot:header>
        <h2>{{ $t('About') }}</h2>
      </template>
      <template v-slot:content>
        <p>{{$t('we-are-the-people-who-have-worked-at-and-solved-some-of-the-biggest-problems-for-some-of-the-worlds-')}}</p>
        <h4 class="title">{{ $t('Jobs') }}:</h4>
        <p>{{ $t('we-want-to-work-with-people-who-want-to-change-things-we-know-that-things-can-and-will-change-usually') }} <a href="mailto:jobs@fluentconcepts.com">jobs@fluentconcepts.com</a></p>
      </template>
    </base-card>
  </div>
</template>
<script>
import BaseCard from '@/components/BaseCard'
export default {
  components: {
    BaseCard
  }
}
</script>

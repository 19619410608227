<template>
  <div class="translate-now page-content">
    <md-dialog :md-active.sync="showErrors" id="error-dialog">
      <md-dialog-title class="error-title">{{ $t('translate-now-view.errors') }}</md-dialog-title>
      <md-dialog-content>
        <p>{{ $t('translate-now-view.the-following-fields-are-empty') }}:</p>
        <div v-for="(error, i) in errors" :key="i" class="error-text">{{$t('translate-now-view.is-required', { error: $t(error) })}}</div>
      </md-dialog-content>
    </md-dialog>
    <div class="fluent-doc-form">
      <base-card>
        <template v-slot:header class="header">
          <h2>{{ $t('translate-now') }}</h2>
          <p>{{ $t('translate-now-view.fill-in-the-following-form-and-we-will-do-the-rest') }}</p>
          <p>{{$t('if-you-have-any-issues-reach-out-to')}} <a href="mailto:support@fluentconcepts.com">support@fluentconcepts.com</a></p>
        </template>
        <template v-slot:content>
          <form id="translation-form" novalidate>
            <base-input-wrapper :class="{'form-group-error' : hasBeenSubmitted && $v.form.documentName.$error}">
              <base-input :label="$t('document-name')" v-model.trim="$v.form.documentName.$model" />
            </base-input-wrapper>
            <div class="error" v-if=" hasBeenSubmitted && !$v.form.documentName.required">{{ $t('translate-now-view.field-is-required') }}</div>
            <div class="error" v-if=" hasBeenSubmitted && !$v.form.documentName.maxLength">{{ $t('translate-now-view.name-is-greater-than-110-characters') }}</div>
            <div class="section-title">
              <h3 class="title">{{ $t('Languages') }}</h3>
              <p class="instruction">{{ $t('select-the-language-of-your-document-and-the-languages-you-want-your-document-translated-into') }}</p>
            </div>
            <div class="flex-row-container languages-container">
              <div style="width: 100%;">
                <translation-component :label="$t('language-of-your-document')" :class="{'form-group-error' : hasBeenSubmitted && $v.form.currentTranslation.$error}" />
                <div class="error" v-if="hasBeenSubmitted && !$v.form.currentTranslation.required">
                  {{ $t('translate-now-view.field-is-required') }}</div>
              </div>
              <div>
                <span class="material-icons sync-icon">sync_alt</span>
              </div>
              <div style="width: 100%">
                <translation-component :label="$t('target-languages')" :is-multiselect="true" :class="{'form-group-error' :hasBeenSubmitted && $v.form.selectedTranslations.$error}" />
                <div class="error" v-if="hasBeenSubmitted && !$v.form.selectedTranslations.required">{{ $t('translate-now-view.field-is-required') }}</div>
              </div>
            </div>

            <div class="form-group">
              <div class="section-title">
                <h3 class="title">{{ $t('Files') }}</h3>
                <p class="instruction">{{ $t('upload-your-document-here') }}</p>
                <file-upload-component v-model="form.uploadFiles" :class="{'form-group-error' : hasBeenSubmitted && $v.form.uploadFiles.$error}" @file:added="onFileAdded" @file:removed="onFileRemoved" />
                <div class="error" v-if="hasBeenSubmitted && !$v.form.uploadFiles.required">{{$t('translate-now-view.at-least-one-file-is-required')}}</div>
              </div>
            </div>
            <div v-if="!isLoggedIn">
              <div class="section-title">
                <h3 class="title">{{ $t('Summary') }}</h3>
                <p class="instruction">{{ $t('the-first-two-translated-pages-are-free') }}</p>
              </div>
              <div class="flex-row-container" style="margin-bottom: 20px">
                <p><span class="muted-text">{{ $t('page-count') }}:</span> {{ pageCount }}</p>
                <p><span class="muted-text">{{ $t('target-languages') }}:</span> {{ numberOfLanguages }}</p>
                <p><span class="muted-text">{{ $t('Cost') }}:</span> ${{ totalCost }}</p>
              </div>
            </div>
            <base-input-wrapper :class="{'form-group-error' : hasBeenSubmitted && $v.form.email.$error}">
              <base-input :label="$t('your-email-address-so-we-can-deliver-your-translation')" v-model="$v.form.email.$model"/>
            </base-input-wrapper>
            <div class="error" v-if=" hasBeenSubmitted && !$v.form.email.required">{{ $t('translate-now-view.field-is-required') }}</div>
            <div class="error" v-if=" hasBeenSubmitted && !$v.form.email.email">{{ $t('translate-now-view.a-valid-email-is-required') }}</div>
            <div class="actions">
<!--              <base-button :css-outline="true" @click="checkoutAsGuest" v-if="!isLoggedIn">{{  $t('Checkout As Guest') }}</base-button>-->
              <base-button v-if="!isLoggedIn" @click="callLogin">{{  $t('Login') }}</base-button>
              <base-button v-if="isLoggedIn" @click="processTranslation">{{  $t('Process') }}</base-button>
            </div>
          </form>
        </template>
      </base-card>
    </div>
  </div>
</template>

<script>
import FileUploadComponent from '@/components/FileUploadComponent'
import TranslationComponent from '@/components/TranslationComponent'
import BaseInputWrapper from '@/components/controls/base/BaseInputWrapper'
import BaseInput from '@/components/controls/base/BaseInput'
import BaseCard from '@/components/BaseCard'
import { email, maxLength, required } from 'vuelidate/lib/validators'
import ClientJSService from 'majda-clientjs/src/services/ClientJSService'
import { mapMutations, mapActions } from 'vuex'
import FileService from '@/services/FileService'
import { storage } from '@/utils/storage'
import UserService from '@/services/UserService'
import BaseButton from '@/components/controls/base/BaseButton'
import DocumentService from '@/services/DocumentService'

export default {
  name: 'TranslateNow',
  components: {
    BaseButton,
    FileUploadComponent,
    TranslationComponent,
    BaseInputWrapper,
    BaseInput,
    BaseCard
  },
  data () {
    return {
      showErrors: false,
      errors: [],
      invalidFields: [],
      client: null,
      fingerprint: null,
      loading: false,
      pageCost: 0,
      pageCount: 0,
      totalCost: 0,
      hasBeenSubmitted: false,
      transactionId: null,
      form: {
        documentName: '',
        currentTranslation: null,
        selectedTranslations: [],
        uploadFiles: [],
        email: null,
        jwt: null
      },
      settings: {
        pageCost: 0
      },
      mapFieldToLabel: {
        documentName: { label: 'Document name' },
        currentTranslation: { label: 'Language of your document' },
        selectedTranslations: { label: 'Target languages' },
        uploadFiles: { label: 'Files' },
        email: { label: 'Email address' }
      }
    }
  },
  validations: {
    form: {
      documentName: {
        required,
        maxLength: maxLength(110)
      },
      email: {
        required,
        email
      },
      currentTranslation: {
        required
      },
      selectedTranslations: {
        required
      },
      uploadFiles: {
        required
      }
    }
  },

  computed: {
    numberOfLanguages () {
      return this.$store.getters.selectedTranslations.length
    },
    selectedTranslations () {
      return this.$store.getters.selectedTranslations
    },
    selectedTranslation () {
      return this.$store.getters.selectedTranslation
    },
    isLoggedIn () {
      return !!(this.$store.getters.user && this.$store.getters.user.token)
    }
  },
  watch: {
    isLoggedIn () {
      this.lookupUser()
    }
  },
  created () {
    this.lookupUser()
  },
  methods: {
    ...mapMutations({
      isLoading: 'isLoading'
    }),
    ...mapActions({
      setCheckoutSession: 'setCheckoutSession'
    }),
    callLogin () {
      this.$emit('open-login')
    },
    async checkout () {
      this.hasBeenSubmitted = true
      try {
        if (!this.formHasErrors()) {
          await this.postUpdate()
          await this.callSave()
        }
      } catch (error) {
        alert(error)
      }
    },
    async checkoutAsGuest () {
      this.hasBeenSubmitted = true
      try {
        if (!this.formHasErrors()) {
          this.isLoading(true)
          await this.postUpdate()
          const saveResponse = await this.callSave()
          const results = saveResponse.data.results
          this.isLoading(false)
          if (saveResponse.status === 200 && saveResponse.data.status === 'ok') {
            const checkoutResults = {
              tempNewProduct: results.temp_new_product,
              tempNewProductFiles: results.temp_new_product_files,
              tempNewProductTargetLanguages: results.temp_new_product_target_languages,
              totalCost: results.total_cost
            }
            this.setCheckoutSession({
              jwt: storage.getValue('jwt'),
              email: this.form.email,
              documentName: this.form.documentName,
              sourceLanguage: this.selectedTranslation,
              targetLanguages: this.selectedTranslations
            })
            await this.$router.push({ name: 'CheckoutSummary', params: { checkoutResults: checkoutResults } })
          }
        }
      } catch (error) {
        alert(error)
      }
    },
    formHasErrors () {
      this.showErrors = false
      this.form.currentTranslation = this.$store.getters.selectedTranslation
      this.form.selectedTranslations = this.$store.getters.selectedTranslations
      this.invalidFields = []
      this.errors = []
      const fields = Object.keys(this.form)
      fields.forEach((field) => {
        if (!this.form[field]) {
          this.addInvalidField(field)
        }
      })

      // Check the target languages if there are any
      if (!this.form.selectedTranslations.length) {
        this.addInvalidField('selectedTranslations')
      }

      if (this.errors.length) {
        this.showErrors = true
      }
      return this.showErrors
    },
    async callSave () {
      const payload = { ...this.form }
      payload.jwt = storage.getValue('jwt')
      payload.currentTranslation = this.selectedTranslation
      payload.selectedTranslations = this.selectedTranslations
      return FileService.save(payload)
    },
    async postUpdate () {
      const postUpdateResponse = await UserService.postUpdate({
        jwt: this.form.jwt,
        fingerprint: this.fingerprint,
        email: this.form.email
      })
      if (postUpdateResponse.data.status === 200) {
        this.form.jwt = postUpdateResponse.data.token
        storage.storeValue('jwt', this.form.jwt)
      }
      return postUpdateResponse
    },
    addFiles (evt) {
      const files = evt.dataTransfer ? evt.dataTransfer.files : evt.target.files
      files.forEach(file => {
        this.form.uploadFiles.push(file)
      })
    },
    async onFileAdded (file) {
      const payload = {
        jwt: storage.getValue('jwt'),
        // This references the File object
        file: file.fileInterface
      }

      try {
        this.isLoading(true)
        await this.callSave()
        const uploadResponse = await FileService.postUpload(payload)
        // assign the file id. This will be needed to remove the file
        file.id = uploadResponse.data.temp_new_product_file_id
        this.pageCost = uploadResponse.data.page_cost
        this.pageCount = uploadResponse.data.page_count
        this.totalCost = uploadResponse.data.total_cost
      } catch (error) {
        alert(error)
      } finally {
        this.isLoading(false)
      }
    },
    async onFileRemoved(removeFileConfig) {
      this.isLoading(true)
      try {
        const file = removeFileConfig.file
        const handler = removeFileConfig.handler
        const token = storage.getValue('jwt')
        if (token && file && file.id && handler) {
          const fileRemoveResponse = await FileService.removeFile(token, file.id)
          const success = fileRemoveResponse.data && fileRemoveResponse.data.success === 1
          if (success) {
            // call handler to remove the icon from the UI
            handler(file)
          }
        }
      } catch (error) {
        alert(error)
      } finally {
        this.isLoading(false)
      }
    },
    uploadFiles () {
      const formData = new FormData()
      formData.append('inputUploadFile', this.form.uploadFiles)
    },
    triggerUpload () {
      this.$refs.fileInput.click()
    },
    addInvalidField (field) {
      this.errors.push(this.mapFieldToLabel[field].label)
      this.invalidFields.push(field)
      if (this.$v.form[field]) {
        this.$v.form[field].$touch()
      }
    },
    async lookupUser () {
      this.client = ClientJSService.getClient()

      let email = ''
      // generate unique Id
      this.fingerprint = this.client.getFingerprint()

      // Set the email value and clear the fingerprint value
      if (this.isLoggedIn) {
        email = this.$store.getters.user.userName
        this.fingerprint = ''
      }

      // Update the email input
      this.$v.form.email.$model = email

      // finally, lookup the user. This will set the jwt value once the response is returned
      this.isLoading(true)
      const userData = {
        email: email,
        fingerprint: this.fingerprint
      }
      const userResponse = await UserService.lookupUser(userData)
      storage.storeValue('jwt', userResponse.data.data.token)
      this.form.jwt = userResponse.data.data.token
      this.isLoading(false)
    },
    async processTranslation () {
      // Call document complete > getComplete
      this.hasBeenSubmitted = true
      try{
        if (!this.formHasErrors()) {
          this.isLoading(true)
          await this.postUpdate()
          const saveResponse = await this.callSave()
          const results = saveResponse.data.results
          const transactionId = results['temp_new_product'].length ? results['temp_new_product'][0]['transaction_id'] : null
          if (saveResponse.status === 200 && saveResponse.data.status === 'ok') {
            // save session
            this.setCheckoutSession({
              jwt: storage.getValue('jwt'),
              email: this.form.email,
              documentName: this.form.documentName,
              sourceLanguage: this.selectedTranslation,
              targetLanguages: this.selectedTranslations
            })
            // check if the file has been moved and saved
            const getCompleteResponse = await DocumentService.getComplete(this.form.jwt)
            if (getCompleteResponse.status === 200 && getCompleteResponse.data.status === 'success') {
              // call process_translation
              await this.postProcessTranslation(getCompleteResponse.data.product_id)
              // redirect to the process page

              if (transactionId) {
                await this.$router.push({
                  name: 'DocumentProcessPage',
                  params: {
                    // product_id that is returned should already be encoded
                    transactionId: btoa(transactionId),
                    email: btoa(this.form.email)
                  }
                })
              }
            }
          }
        }

      } catch (error) {
        alert(error)
      } finally {
        this.isLoading(false)
      }
    },
    getFileName() {
      // NOTE: This returns the name of the file name
      let name = ''
      if (this.form.uploadFiles.length) {
        name = this.form.uploadFiles[0].name
      }
      return name
    },
    async postProcessTranslation (productId) {
      const user = this.$store.getters.user
      if (user) {
        const processTranslationPayload = {
          email: this.form.email,
          productId: productId,
          libraryUserId: user.libraryUserId,
          libraryId: user.library,
          userName: user.userName,
          originalFileName: this.getFileName(),
          jwt: this.form.jwt
        }
        return await UserService.processTranslation(processTranslationPayload)
      }
    }
  }
}
</script>

<style scoped lang="scss">

$errorColor: #ff0000;

@import "../styles/app-styles";

.header {
  font-family: var(--font-roboto);
}
p {
  color: var(--main-font-color);
  font-size: 16px;
}

#translation-form {
  text-align: left;

  .sync-icon {
    color: var(--button-and-link-colors);
    font-size: 42px;
    margin: 0 40px;
  }

  label {
    color: var(--input-label-color)
  }

  .title {
    color: var(--input-label-color);
    font-size: 1.2rem;
  }

  .md-field label {
    color: var(--input-label-color) !important;
  }

  ::-webkit-input-placeholder, :-ms-input-placeholder, ::placeholder {/* Chrome/Opera/Safari/Edge */
    color: $errorColor;
  }

  .section-title, .form-group{
    margin-bottom: 1rem;
  }

  .section-title h3 {
    color: var(--button-and-link-colors);
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .checkout-button {
    padding: 12px 36px;
    margin-right: 0;
    background-color: var(--main-theme-secondary-color);
  }

  .languages-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .form-group-error {
    border: 1px solid $errorColor;
    margin-bottom: 1rem;
  }

  .error {
    color: $errorColor;
  }
}

#error-dialog{
  .error-title, .error-text {
    color: $errorColor;
  }

  .error-title {
    background-color: var(--main-theme-color);
    padding: 1rem 20px;
    color: white;
  }
}

</style>

<template>
<div class="page-content"></div>
</template>

<script>
export default {
  name: 'Login'
}
</script>

<style scoped>

</style>

import Vue from 'vue'
import Vuex from 'vuex'
import i18n, { selectedLocale } from '@/i18n'

Vue.use(Vuex)

const state = {
  loading: false,
  appStyles: {
    logo: 'fluent',
    type: 'fluent'
  },
  checkoutSession: {
    jwt: null,
    email: null,
    documentName: null,
    sourceLanguage: null,
    targetLanguages: []
  },
  locale: selectedLocale
}

const mutations = {
  isLoading (state, loading) {
    state.loading = loading
  },
  setAppStyles (state, appStyles) {
    state.appStyles = appStyles
  },
  updateLocale (state, newLocale) {
    state.locale = newLocale
  },
  updateCheckoutSession (state, checkout) {
    state.checkoutSession = {
      jwt: checkout.jwt,
      email: checkout.email,
      documentName: checkout.documentName,
      sourceLanguage: checkout.sourceLanguage,
      targetLanguages: checkout.targetLanguages
    }
  }
}

const actions = {
  changeLocale ({ commit }, newLocale) {
    i18n.locale = newLocale
    commit('updateLocale', newLocale)
  },

  setCheckoutSession ({ commit }, checkout) {
    commit('updateCheckoutSession', checkout)
  }
}

const getters = {
  loading: state => state.loading,
  appStyles: state => state.appStyles,
  locale: state => state.locale,
  checkoutSession: state => state.checkoutSession
}

export default {
  state,
  actions,
  mutations,
  getters
}

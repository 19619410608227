<template>
  <div class="base-input">
    <input
      :id="inputId"
      v-bind="$attrs"
      :type="type"
      :name="inputName"
      :disabled="disabled"
      :value="value"
      :minlength="minlength"
      :class="{'error': isInvalid}"
      @input="handleInput"
    >
    <label
      :for="inputId"
      :class="{'has-value': value}"
    >{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    id: String,
    label: {
      type: String,
      required: true
    },
    disabled: Boolean,
    inValid: Boolean,
    value: String,
    type: String,
    placeholder: String,
    isInvalid: Boolean,
    minlength: Number,
    hideLabel: Boolean
  },
  computed: {
    inputName () {
      if (this.label === undefined) {
        throw new Error('Label is undefined')
      }

      return 'base-input-' + this.label.split(' ').join('-')
    },
    inputId () {
      if (this.label === undefined) {
        throw new Error('Label must be defined')
      }

      let idVal = 'base-input'
      if (!this.id) {
        idVal = 'base-input-' + this.label.split(' ').join('-')
      } else {
        idVal = this.id
      }
      return idVal
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/app-styles";
.base-input {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #c6c9cb;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0;
  width: 100%;
  margin: 0;
  position: relative;
  height: 56px;
  background-color: rgb(245,245,245);

  label {
    padding: 16px;
    position: absolute;
    color: var(--color-primary-30);
    top: 0;
    transition: all .2s ease;
  }

  input {
    position: absolute;
    top: 10px;
    bottom: 0;
    width: 100%;
    padding: 20px 16px;
    font-weight: bold;
    border: 0;
    background: transparent;
  }

  input:focus {
    outline: none;
    color: #000;
    border-color: var(--color-primary);
  }
  &:focus-within {
    border-bottom-color: var(--input-focus-color);
    color: var(--color-primary-30);
    background-color: rgba(0,0,0,0.1)
   }
  &:focus-within label {
     font-size: 12px;
     top: -10px;
     color: var(--input-focus-color);
   }

  .has-value {
    font-size: 12px;
    top: -10px;
  }

  &.validation-error input {
     border-color: var(--input-error-color);
   }

  .hide-label {
    display: none;
  }
}
::-webkit-input-placeholder, :-ms-input-placeholder, ::placeholder {/* Chrome/Opera/Safari/Edge */
  color: var(--color-primary)
}
</style>

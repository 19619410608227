<template>
  <div class="base-input-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseInputWrapper'
}
</script>

<style lang="scss">
.base-input-wrapper {
  position: relative;
  flex: 1;
  margin-bottom: 20px !important;

  &.validation-error {

    .base-input {
      border-color: #ff0000 !important;
    }

    .error-message {
      margin: 5px 5px 0;
      color: #ff0000;
    }
  }
}
</style>

<template>
<div class="document-complete page-content">
  <h1>Processing Documents</h1>
  <div class="document-complete-content">
    <div v-if="errorMessage">
      <h3 class="error-h3">Opps ...something went wrong!</h3>
      <p class="error">{{ errorMessage }}</p>
      <p>{{$t('if-you-have-any-issues-reach-out-to')}} <a href="mailto:support@fluentconcepts.com">support@fluentconcepts.com</a></p>
    </div>
    <div v-else>
      Searching documents ...please wait
      <app-progress-bar />
    </div>
  </div>
</div>
</template>

<script>
import DocumentService from '@/services/DocumentService'
import AppProgressBar from '@/components/controls/AppProgressBar'
export default {
  name: 'DocumentComplete',
  components: {
    AppProgressBar
  },
  data() {
    return {
      errorMessage: null
    }
  },
  async created () {
    const jwt = this.$route.params.jwt
    const email = this.$route.params.email
    const getCompleteResponse = await DocumentService.getComplete(jwt)
    if (getCompleteResponse.status === 200 && getCompleteResponse.data.status === 'success') {
      const productId = getCompleteResponse.data.product_id
      await this.$router.push({ name: 'DocumentProcessPage', params: { productId: btoa(productId), email: email }})
    } else {
      this.errorMessage = getCompleteResponse.data.message
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/app-styles";
.document-complete {
  text-align: left;
  margin-top: 3rem;
  padding: 1rem;
  h1 {
    color: var(--button-and-link-colors);
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--button-and-link-colors);
  }
  &-content {
    max-width: 80%;
    margin: 2rem auto;
  }
}
</style>

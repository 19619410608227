import {AppUtils} from '@/utils/AppUtils'

export const StripeUtils = {
  create (sessionOptions) {
    sessionOptions = sessionOptions || {}
    const host = `https://${AppUtils.getHost()}`
    const jwt = sessionOptions.jwt ? sessionOptions.jwt : null
    const encodedEmail = sessionOptions.email ?  btoa(sessionOptions.email) : null
    const successUrl = sessionOptions.successUrl ? sessionOptions.successUrl : `${host}/#/document-complete/${jwt}/${encodedEmail}`
    return {
      email: sessionOptions.email,
      description: sessionOptions.description,
      paymentMethodTypes: sessionOptions.paymentMethodTypes ? sessionOptions.paymentMethodTypes : ['card'],
      mode: sessionOptions.mode ? sessionOptions.mode : 'payment',
      lineItems: sessionOptions.lineItems ? sessionOptions.lineItems : [],
      successUrl: successUrl,
      cancelUrl: sessionOptions.cancelUrl ? sessionOptions.cancelUrl : host + '/#/translate-now',
      host: host
    }
  }
}

<template>
  <div class="translation-modal">
    <h2>Languages</h2>
    <div v-if="isLoading">
      <p>Loading....</p>
      <md-progress-bar md-mode="indeterminate"></md-progress-bar>
    </div>
    <transition name="fade">
      <div v-if="languages.length && !isLoading" class="languages">
        <div v-for="language in languages" :key="language.id" class="language">
          <input v-if="isMultiselect" type="checkbox" class="toggle" :value="language" v-model="selectedLanguages">
          <input v-if="!isMultiselect" type="radio" class="toggle" :value="language" v-model="selectedLanguage">
          <label class="language-label">
            <span class="language-flag" :class="getFlagIcon(language)"></span>
            <span class="language-name">{{language.name}}</span>
          </label>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'TranslationModal',
  props: {
    isMultiselect: Boolean
  },
  data () {
    return {
      translationLanguages: [],
      isLoading: false
    }
  },
  created () {
    this.getLanguages()
  },
  computed: {
    languages () {
      if (this.$store.getters.translations.length) {
        return this.$store.getters.translations.map((language) => {
          return this.mapLanguage(language)
        })
      }
      return []
    },
    selectedLanguages: {
      get () {
        return this.$store.getters.selectedTranslations
      },
      set (translations) {
        this.setSelectedTranslations(translations)
      }
    },
    selectedLanguage: {
      get () {
        return this.$store.getters.selectedTranslation
      },
      set (translation) {
        this.setSelectedTranslation(translation)
      }
    }
  },
  watch: {
    selectedLanguages (languages) {
      if (this.isMultiselect) {
        this.setSelectedTranslations(languages)
      }
    },
    selectedLanguage (language) {
      this.setSelectedTranslation(language)
    }
  },
  methods: {
    ...mapMutations({
      setSelectedTranslation: 'setSelectedTranslation',
      setSelectedTranslations: 'setSelectedTranslations'
    }),
    getFlagIcon (language) {
      return 'flag-icon flag-icon-' + language.flagCode
    },
    mapLanguage (flagData) {
      return {
        id: flagData.language_id,
        flagCode: flagData.flag_code,
        name: flagData.name
      }
    },
    setCheckId (language) {
      return 'check-input-' + language.name
    },
    async getLanguages () {
      if (!this.$store.getters.translations.length) {
        this.isLoading = true
        await this.$store.dispatch('getLanguages')
        this.isLoading = false
        this.translationLanguages = this.$store.getters.translations.length
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../css/flag-icon-css-master/css/flag-icon.css";
@import "../../styles/app-styles.scss";
@import "../../styles/transitions.scss";

  .translation-modal {
    padding: 1rem;
  }

  .languages {
    display: grid;
    grid-template-columns: repeat(3, 1.2fr);
    grid-auto-rows: 40px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: 0;
  }

  .language {
    position: relative;
    background-color: #f0f0f0;
    border-radius: 4px;
  }

  .language-label {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 4px;
  }

  .language-label > * {
    flex: 1;
  }

  .toggle {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 90;
  }

  .toggle:checked ~ .language-label {
    background-color: var(--main-theme-secondary-color);
    color: #ffffff;
    border-color: var(--main-theme-secondary-color);
  }

</style>

<template>
  <div id="nav" class="content-container-shadow">
    <div class="nav-container">
      <div class="sub-nav-container">
        <site-logo />
        <div style="flex: 1;" class="nav-links" :class="[{'nav-links-show': showMenu}, {'nav-links-hide': !showMenu}, {'content-container-shadow': showMenu}]">
          <div class="nav-link-wrapper"> <a id="navItemHome" @click="goToPath('/')" class="nav-link" :class="checkIfIsActiveRoute('/')">{{ $t('Home') }}</a></div>
          <div class="nav-link-wrapper"><a id="navItemAbout" @click="goToPath('/about')" class="nav-link" :class="checkIfIsActiveRoute('/about')">{{ $t('About') }}</a></div>
          <div class="nav-link-wrapper"><a id="navItemHowItWorks" @click="goToPath('/how-it-works')" class="nav-link" :class="checkIfIsActiveRoute('/how-it-works')">{{ $t('how-it-works') }}</a></div>
          <div class="nav-link-wrapper"><translate-now-button class="translate-button" @click="showMenu=false" /></div>
        </div>
        <span><language-dropdown /></span>
        <div class="user-link" v-if="userName">
          <span class="username">{{ userName }}</span>
          <span>|</span>
          <a id="navItemLogoutUser" class="logout-link" @click="callLogout">logout</a>
        </div>
        <div v-if="!userName">
          <a id="navItemLogin" @click="callLogin" class="login-link">{{ $t('Login') }}</a> | <a id="navItemRegister" @click="callRegister" class="login-link">{{ $t('Register') }}</a>
        </div>
      </div>
      <div class="nav-menu" style="align-self: flex-end;"><md-button class="md-icon-button md-primary md-dense" id="menuButton" @click="onShowMenu">
        <md-icon id="menuIconDefault" class="menu-icon" v-if="!showMenu">menu</md-icon>
        <md-icon id="menuIconClose" class="menu-icon" v-if="showMenu">close</md-icon>
      </md-button> </div>
    </div>
  </div>
</template>

<script>

import LanguageDropdown from '@/components/LanguageDropdown'
import TranslateNowButton from '@/components/TranslateNowButton'
import SiteLogo from '@/SiteLogo'
export default {
  name: 'AppHeader',
  components: { SiteLogo, TranslateNowButton, LanguageDropdown },
  data () {
    return {
      showMenu: false
    }
  },
  computed: {
    currentRoute () {
      return this.$route.path
    },
    userName () {
      let userName = null
      if (this.$store.getters.user && this.$store.getters.user.userName) {
        const fullUserName = this.$store.getters.user.userName
        const splitUserName = fullUserName.split('@')
        if (splitUserName.length > 1) {
          userName = splitUserName[0]
        } else {
          userName = fullUserName
        }
      }
      return userName
    }
  },
  methods: {
    onShowMenu () {
      this.showMenu = !this.showMenu
    },
    async goToPath (path) {
      await this.$router.push(path)
      this.showMenu = false
    },
    checkIfIsActiveRoute (path) {
      return {
        'router-link-exact-active': this.currentRoute === path
      }
    },
    callLogin () {
      this.$emit('open-login')
    },
    callLogout () {
      this.$emit('logout')
    },
    callRegister() {
      this.$emit('open-register')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/app-styles";
#nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--nav-background-color);
  font-family: var(--font-roboto);
  color: var(--nav-font-color);
  z-index:8;
  padding: 0.25rem;
  opacity: 0.98;
  min-height: 126px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .nav-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }

  .nav-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    justify-content: space-between;
    position: relative;
  }

  .sub-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
  }

  .nav-container.responsive {
    display: flex;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
    top: 100%;
    background-color: var(--nav-background-color);
    width: 100%;
    padding: 2rem;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 400ms ease-in-out;
  }

  .nav-links > .nav-link-wrapper {
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  a#navItemLogoutUser {
    cursor: pointer;
  }

  .md-button.translate-now-button {
    font-size: 14px;
    padding: 0;
    min-height: 0;
    min-width: 0;
    border-radius: 4px;
    margin-left: 2rem;
  }

  .user-link {
    display: grid !important;
    font-size: 14px;
    grid-gap: 0.25rem;
    align-items: center;
    grid-template-columns: repeat(4, auto);
    justify-content: space-between !important;
  }

  .username {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100px;
  }

  .login-link, .logout-link {
    cursor: pointer;
  }

  .nav-links > .nav-link-wrapper:last-child {
    margin-right: 0;
  }

  .nav-links-show {
    transform: scale(1, 1);
  }

  .nav-links-show .nav-link-wrapper {
    opacity: 1;
    transition: opacity 250ms ease-in-out 500ms;
  }

  .menu-icon {
    color: var(--button-and-link-colors);
  }

  .nav-link-wrapper {
    opacity: 0;
    display: inline-block;
    transition: opacity 100ms ease-in-out;
  }

  a.nav-link {
    cursor: pointer;
    position: relative;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
  }

  a.nav-link::before {
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background: var(--button-and-link-colors);
    position: absolute;
    top: -15px;
    left: calc(50% - 5px);
    right: 0;
    transform: scale(0,0);
    transition: transform ease-in-out 250ms;
  }

  a.nav-link:hover {
    opacity: 1;
  }

  a.nav-link:hover::before {
    transform: scale(1,1);
  }

  a.nav-link {
    font-weight: bold;
    color: var(--nav-font-color) !important;
    opacity: .5;

    &.router-link-exact-active {
      color: var(--nav-font-color) !important;
      opacity: 1;
    }

    &.router-link-exact-active::before {
      transform: scale(1,1);
    }
  }

  /* FIX: md-ripple width was not being calculated correct. */
  ::v-deep .md-button .md-ripple {
    min-width: 0;
    width: inherit;
  }

  /* Device widths less than 939px */
  @media only screen and (max-width: 939px) {

    padding: 0;

    .nav-brand {
      margin: 1rem 0 1rem 2rem;
    }

    .nav-menu {
      margin: 1rem 1rem 1rem 0;
    }

    .nav-container .nav-menu {
      display: block;
    }

    .nav-links .nav-link-wrapper {
      margin-top: 1rem;
      justify-content: start;
      align-items: start;
      text-align: left;
      padding: 0;

      .translate-now-button {
        margin-left: 0;
      }
    }
    .nav-links .nav-link-wrapper a.nav-link::before, .nav-links .nav-link-wrapper .router-link-exact-active::before {
      display: none;
    }
  }

  @media only screen and (max-width: 1290px) {
    .sub-nav-container {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .nav-links {
      margin: 0 -1rem;
    }
  }

  /* Large devices (laptops/desktops, 940px and up) */
  @media only screen and (min-width: 940px) {

    .nav-container {

      .nav-links {
        position: relative;
        all: unset;
        display: flex;
        max-width: 50%;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
      }

      .nav-links .nav-link-wrapper {
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .nav-menu {
        display: none;
      }

    }
  }
}
</style>
